/*
Documentation

this page holds all of our layouts and functions
that need to fire before the app runs

*/

import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";


import Legal from 'views/legal/layout';
import Errors from 'views/errors/layout';
import Site from 'views/site/layout';
import Docs from 'views/docs/layout';
import Faq from 'views/faq/layout';
import Landing from 'views/landing/layout';

// import Auth from 'views/auth/layout';
// import Dashboard from 'views/dashboard/layout';

// import FailedConnection from 'views/errors/pages/errors/FailedConnection';

import SystemAlertBS from 'components/markup/system/AlertBS'
import SystemAlert from 'components/markup/system/Alert'
import SystemLoader from 'components/markup/system/Loader'
import StandardLoader from 'components/markup/system/StandardLoader'

import ScrollToTop from 'components/functional/scroll/ScrollToTop'

// import LoggedInRoute from 'components/system/Routing/LoggedInRoute'

// import init from './init'

class App extends Component {

    state = {
        loaded: false,
        error: false
    };

    componentDidMount = async () => {

        // const loadedSuccessfully = await init();
        // this.setState({loaded: true, error: !loadedSuccessfully})

    }

    componentDidCatch = (e) => {

        //don't redirect if we are developing
        if(window.location.hostname !== 'localhost') {
            this.setState({shouldRedirect: `/errors/standard?error=${e}&referrerurl=${window.location.href}`})
        }

    }

    render() {

        // if(this.state.shouldRedirect) {
        //     return window.location.href = this.state.shouldRedirect
        // }

        // if(!this.state.loaded) {
        //     return <div></div>
        // }

        // if(this.state.error) {
        //     return <FailedConnection />
        // }

        return (

            <>

                <SystemAlertBS />
                <SystemAlert />
                <SystemLoader />
                <StandardLoader />

                <BrowserRouter>
                    <ScrollToTop>

                        <Switch>

                            <Route path="/legal" render={props => <Legal {...props} /> } />
                            <Route path="/errors" render={props => <Errors {...props} /> } />
                            <Route path="/docs" render={props => <Docs {...props} /> } />
                            <Route path="/faq" render={props => <Faq {...props} /> } />
                            <Route path="/l" render={props => <Landing {...props} /> } />
                            <Route path="/" render={props => <Site {...props} /> } />

                        </Switch>

                    </ScrollToTop>
                </BrowserRouter>

            </>

        )

    }

}

export default App
