import apiWorker from '../apiWorker'

const integrations = {

    yodlee: {
        providers: {
            search : (string) => apiWorker('get',  `/v1/integrations/agg2/providers/search?search=${string}`, null, true),

        }
    },
    mx: {
        institutions: {
            search : (search) =>  apiWorker('get',  `/v1/integrations/agg1/institutions/search?search=${search}`, null, true),
        }
    },
    pinwheel: {
        employers: {
            search : (string) =>  apiWorker('get',  `/v1/integrations/agg3/search?q=${string}`, null, true),
        }
    }

}

export default integrations;