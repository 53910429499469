import keys from 'keys';
import { useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Input, Form, FormGroup, Card, CardHeader, CardTitle, CardFooter } from 'reactstrap';

import _leads from '_functions/leads';

const Webinar2 = () => {

    const [err, setErr] = useState()
    const [email, setEmail] = useState()
    const [name, setName] = useState()
    const [accepted, setAccepted] = useState(false)
    const link = keys.CLIENT_URL + '/auth/register?p=webinar'

    const onSubmit = useCallback(async() => {
        if(!name) return setErr(true)
        if(!email) return setErr(true)
        _leads.create({ name, email })
        setAccepted(true)
    }, [email, name])
 
    return (

        <div id="page-home" style={{overflow: 'hidden'}}>

            <Helmet>
                <title>{`Zap Reports Webinar`}</title>
                <meta name="description" content="Zap Reports Webinar" />
            </Helmet>

            <Container className="my-4">

                <Row>
                    {!accepted ? (
                        <Col md={4}>

                            <img src={keys.LOGO} style={{maxWidth: 250}} className="w-100 mb-4" alt="Zap Reports" />

                            <Card className="border z-depth-1" style={{borderRadius: 0}}>
                                <CardHeader>
                                    <CardTitle className="mb-0">Zap Reports Webinar</CardTitle>
                                </CardHeader>
                                
                                    <CardHeader>
                                        <p className="text-sm ">Enter your law firm name and your email address to view the full Zap Reports Webinar.</p>
                                        <p className="text-sm mb-0">Your information is save kept safe and confidential.</p>

                                    </CardHeader>

                                    <CardHeader>
                                        
                                        <Form>
                                            <FormGroup>
                                                <label className="form-control-label">Law Firm Name</label>
                                                <Input 
                                                    style={{borderRadius: 0}}
                                                    type="text"
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <label className="form-control-label">Email Address</label>
                                                <Input 
                                                    style={{borderRadius: 0}}
                                                    type="text"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Form>
                                    </CardHeader>

                                    {err ? (
                                        <CardFooter>
                                            <p className="text-warning mb-0">Please enter your law firm name and email above</p>
                                        </CardFooter>
                                    )  : null}

                                    <CardFooter>
                                        <button 
                                            onClick={onSubmit} 
                                            style={{borderRadius: 0}} 
                                            className="btn btn-success btn-block btn-lg"
                                        >
                                            Watch The Video <i className="fas fa-play ml-3 " />
                                        </button>
                                    </CardFooter>

                            </Card>

                        </Col>
                    ) : null}

                    <Col md={accepted ? 12 : 8}>
                        <h2 className="display-4 text-cente mb-0   ">Pay Stubs & Bank Statements In Seconds</h2>
                        <div className="video-container mt-3" style={{overflow: 'visible'}}>
                            {!accepted ? (
                                <div 
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        left: 0,
                                        bottom: 0,
                                        background: 'rgba(0,0,0,.7  )',
                                        zIndex: 999
                                    }}
                                >
                                    <p 
                                        className="text-white text-center"
                                        style={{
                                            fontSize: 24,
                                            textShadow: '2px 2px 2px black',
                                            fontWeight: 'bold',
                                            position: 'absolute',
                                            top: '25%',
                                            left: 20,
                                            right: 20,

                                        }}
                                    >
                                        Enter Your Law Firm Name and Email To Watch The Video
                                    </p>
                                </div>
                            ) : null}
                            <iframe 
                                style={{zIndex: 2}} 
                                src="https://player.vimeo.com/video/700817917?h=177e0c2d15&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" 
                                frameBorder="0" 
                                allow="autoplay; fullscreen; picture-in-picture" 
                                allowFullScreen 
                                title="Zap Reports Walkthrough.mp4"
                            />
                        </div>

                        {accepted ? (
                            <div>
                                <p className=' mb-0 mt-4 text-sm'>Register your account using this link and get $100 of free Zap Reports credits:</p>
                                <p className="lead font-weight-bold mt-0"><a className="text-info text-underline" href={link}>{link}</a></p> 
                            </div>
                        ) : (
                            <p className="text-center">See how firms across the country are utilizing Zap Reports to make their document collection process a breeze.</p>
                        )}

                    </Col>
                </Row>

            </Container>

        </div>
    )
    

}

export default Webinar2