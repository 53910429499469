import keys from 'keys';
import { Row, Col, Card, CardHeader, CardTitle, CardFooter } from 'reactstrap';
import Contacts1 from './img/Contacts1.jpg'
import Contacts2 from './img/Contacts2.jpg'
import Contacts3 from './img/Contacts3.jpg'
import Contacts4 from './img/Contacts4.jpg'
import Contacts5 from './img/Contacts5.jpg'

import Dashboard from './img/Dashboard.jpg'

import Dashboard1 from './img/Dashboard1.jpg'
import Dashboard2 from './img/Dashboard2.jpg'
import Dashboard3 from './img/Dashboard3.jpg'

import Connect1 from './img/Connect1.jpg'
import Connect2 from './img/Connect2.jpg'

import Docs from 'components/system/Docs';

const Faq = () => (
    <Docs 
    settings={{
        logo: keys.LOGO,
        lastUpdated: '1/23/2022'
    }}
    tabs={[
        {
            name: 'Getting Started',
            content: (
                <div>
                    <Row>
                        <Col md={6}>
                            <p className="mb-0">Zap Reports is organized around the idea that simple is better.</p>
                            <p>The entire platform revolves around <b className="text-white">3 areas.</b></p>
                            <ol>
                                <li>Creating Contacts</li>
                                <li>Requesting Statements</li>
                                <li>Downloading Documents</li>
                            </ol>
                        </Col>
                        <Col md={6}>
                            <Card className="not-sticky">
                                <CardHeader>
                                    <CardTitle className="mb-0">Documents In Seconds</CardTitle>
                                </CardHeader>
                                <CardFooter>
                                    <p className="mb-0">Getting your firm the information it needs to file cases for your clients quickly while saving everyone time, effort, and frustration.</p>
                                </CardFooter>
                            </Card>
    
                        </Col>
                    </Row>
    
                </div>
            ),
    
            tabs: [
                {
                    name: '1. Creating Contacts',
                    content: (
                        <div>
                            <p>To create a contact navigate to the <b className="text-white">"Contacts"</b> tab on the left hand sidebar.</p>
                            <p>Once there click the green <b className="text-white">"Add New"</b> button in the top right of your screen. </p>
                            <p>This will bring down a drop down where you can add the contacts <b className="text-white">name, email, and phone number.</b></p>
            
                            <img src={Contacts1} alt="contact page" />
            
                            <p>Enter in any fields you would like and take note that the email and phone number that you add for the contact will be the addresses that get notifications to log into Zap Reports</p>
                            
                            <img src={Contacts2} alt="add contact" />
            
                        </div>
                    )
                },
                {
                    name: '2. Sending Requests',
                    content: (
                        <div>
                            <p>Once a contact has been made you are ready to send out a request for their statements.</p>
                            <p>This can be done in just 2 clicks by:</p>

                            <h4 className="mb-4">1. Clicking the green "Request" button on the top right of a contacts profile.</h4>
            
                            <img src={Contacts3} alt="contact page" />
            
                            <h4 className="mb-4">2. Clicking the green "Confirm" button that drops down.</h4>
                            
                            <img src={Contacts4} style={{width: 600}} alt="add contact" />
                            
                            <p>
                                <i className="fas fa-check mr-2 text-success " />
                                At this point a request for statements has been sent off to the contact and once they complete the process of pulling their statements the documents will show up inside their portal.
                            </p>
                            <p>
                                <i className="fas fa-info-circle mr-2 text-info " />
                                Note that just creating a contact will <b className="text-warning">not allow them to proceed through the portal.</b> A Request for documents <b className="text-success">must be sent out to them</b> or the portal will not allow them to continue. If a contact has completed the portal a new Request must be sent to them if they need to access the portal again.
                            </p>
            
                        </div>
                    )
                },
                {
                    name: '3. Downloading Docs',
                    content: (
                        <div>
                            <p>To download a contact's statements from a financial institution or payroll provider click the "Documents" tab on the left hand sidebar.</p>
                            <p>Find the document you wish to view and click the green download button to be able to view it.</p>
            
                            <img src={Contacts5} alt="contact page" />
            
                        </div>
                    )
                },
            ]
        },

        {
            name: 'Using The Dashboard',
            content: (
                <div>
                    <p>The Zap Reports Dashboard can be thought of in 3 parts:</p>
                    <ol>
                        <li className="text-sm">Viewing your <span className="text-white">monthly overview</span></li>
                        <li className="text-sm">Viewing your <span className="text-white">recent contacts</span></li>
                        <li className="text-sm">Viewing your <span className="text-white">recent documents</span></li>
                    </ol>
                    <img src={Dashboard} alt="contact page" />

                </div>
            ),
            tabs: [
                {
                    name: '1. Monthly Overview',
                    content: (
                        <div>
                            <p>Your monthly overview shows a breakdown of all contacts and documents that have been created since the start of the current month.</p>

                            <p className="mb-0 text-white">Documents are broken down into the following categories:</p>
                            <ol>
                                <li className="text-sm">Completed - Ready for download</li>
                                <li className="text-sm">In Queue - Waiting to be processed</li>
                                <li className="text-sm">Pending - Awaiting approval from a member of the Zap Reports audit team</li>
                                <li className="text-sm">Failed - The document is unable to be generated</li>
                            </ol>

                            <p className="mb-0 text-white">Contacts are broken down into the following categories:</p>
                            <ol>
                                <li className="text-sm">Finished - They have completed the client portal and confirmed all accounts are linked</li>
                                <li className="text-sm">In Progress - The contact has logged in the portal but has not finished it</li>
                                <li className="text-sm">Requested - A request for documents has been sent out but they have not logged into the portal.</li>
                                <li className="text-sm">No Status - The contact was created but no request for documents has been sent to them.</li>
                            </ol>

                            <img src={Dashboard1} alt="contact page" />

                        </div>
                    )
                },
                {
                    name: '2. Recent Contacts',
                    content: (
                        <div>
                            <p>This list will show the contact information and status of the 10 most recent contacts added to your Zap Reports account.</p>
                            <img src={Dashboard2} alt="contact page" />

                        </div>
                    )
                },
                {
                    name: '3. Recent Documents',
                    content: (
                        <div>
                            <p>This list will show the 10 most recent completed documents for your zap reports account.</p>
                            <img src={Dashboard3} alt="contact page" />

                        </div>
                    )
                },
            ]

        },

        {
            name: 'Connect',
            content: (
                <div>
                    <p>Keeping client information up to date in the Zap Reports dashboard.</p>
                </div>
                
            ),
            tabs: [
                {
                    name: 'Linking Accounts',
                    content: (
                        <div>
                            <p>Zap Reports also allows firm's staff members to enter credentials on behalf of a client should they give permission. This work by navigating to a contacts profile and clicking on the "Connect" Tab. Here a staff member may click on the buttons "Connect Banks", "Link Finances", or "Link Employment" to log into the respective accounts.</p>
                            <p>Note that the end user should be present for this (on the phone with a staff member for example) as MFA may be needed to connect an account such as entering a security code texted to the end user.</p>

                            <img src={Connect1} alt="contact page" />

                        </div>
                    )
                },
                {
                    name: 'Getting Updated Docs ',
                    content: (
                        <div>
                            <p>Zap Reports attempts to keep all account information synced for 90 days. During this time, if the institution allows, a staff member may be able to request an updated bank statement, pay stub, etc.</p>
                            <p>To do this a staff member may navigate the same Connect tab as they would use to link an end users account and check any boxes next to the documents they wish to fetch updated information for. A dropdown will appear and once confirmed it will add the accounts into the queue to create new and updated documents with the clients information.</p>

                            <img src={Connect2} alt="contact page" />

                        </div>
                    )
                },
            ]
        },
        {
            name: 'Zap Reports Portal',
            content: (
                <div>
                    <p>The Zap Reports portal takes just a few minutes to complete and is very simple to use.</p>
                    <p>It is explained in detail via the video below.</p>
    
                    <div className="video-container">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/qTkPhiWZ_xw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
    
                </div>
                
            )
        },

        {
            name: 'Document Status',
            content: (
                <div>
                    <p>Once a document has been set for download it may contain one of the following statuses: Completed, In Queue, Retrying, Aggregating, Verifying, Failed</p>
                    <p>These indicators give insight into the connection status of an account as well as an estimated timeline before they can be downloaded </p>
                </div>
                
            ),
            tabs: [
                {
                    name: 'Completed',
                    content: (
                        <p>This indicates the document is ready for download, no further actions is needed.</p>
                    )
                },
                {
                    name: 'In Queue',
                    content: (
                        <p>An account connection has been made and the document is awaiting processing.</p>
                    )
                },
                {
                    name: 'Retrying',
                    content: (
                        <p>An account connection has been made however Zap Reports was unable to gain information about the document. This status indicates a user has likely changed their password or disconnected their account shortly after going through the portal.</p>
                    )
                },
                {
                    name: 'Aggregating',
                    content: (
                        <p>Zap Reports is searching for more information on a document and will try to retrieve the document shortly.</p>
                    )
                },
                {
                    name: 'Verifying',
                    content: (
                        <p>A Zap Reports staff member is checking over the document, it should be available for download shortly.</p>
                    )
                },
                {
                    name: 'Failed',
                    content: (
                        <p>Zap Reports was unable to retrieve the document. This is similar to the "Retrying" status however at this point Zap Reports will no longer make an attempt at to fetch the document.</p>
                    )
                },
            ]
        },

    ]}
    
    />
)

export default Faq;