import keys from 'keys';
import { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { getUrlParameter, removeUrlParameters } from 'utils/urls';

import { Col, Container, Nav, NavItem, Row, NavbarBrand, UncontrolledCollapse, NavLink, Navbar } from "reactstrap";

const NavbarDesktop = () => {

    const tryScroll = useCallback((id) => {
        const elem = document.getElementById(id);
        if(elem) elem.scrollIntoView({behavior: 'smooth'});
    }, [])

    useEffect(() => {
        const view = getUrlParameter('view')
        removeUrlParameters();
        if(view === 'search') return tryScroll('archk-home-search')
        if(view === 'demo') return tryScroll('archk-home-demo')
    }, [tryScroll])

    return (
        <Navbar
            className="navbar-horizontal navbar-main navbar-dark border-bottom "
            expand="lg"
            id="desktop-navbar-top "
        >
            <Container>
                <NavbarBrand to="/" tag={Link}>
                    <img alt="Zap Reports" src={keys.LOGO} />
                </NavbarBrand>
                
                <button
                    aria-controls="navbar-collapse"
                    aria-expanded={false}
                    aria-label="Toggle navigation"
                    className="navbar-toggler"
                    data-target="#navbar-collapse"
                    data-toggle="collapse"
                    id="navbar-collapse"
                    type="button"
                >
                    <span className="navbar-toggler-icon" />
                </button>
    
                <UncontrolledCollapse navbar className="navbar-custom-collapse"  toggler="#navbar-collapse" >
    
                    <div className="navbar-collapse-header">
                        <Row>
                            <Col className="collapse-brand" xs="6">
                                <Link to="/admin/dashboard">
                                    <img style={{width: 250, height: 'auto',}} alt="Zap Reports" src={keys.LOGO} /> 
                                </Link>
                            </Col>
                            <Col className="collapse-close" xs="6">
                                <button
                                    aria-controls="navbar-collapse"
                                    aria-expanded={false}
                                    aria-label="Toggle navigation"
                                    className="navbar-toggler"
                                    data-target="#navbar-collapse"
                                    data-toggle="collapse"
                                    id="navbar-collapse"
                                    type="button"
                                    style={{position: 'relative', top: -5}}
                                >
                                    <span />
                                    <span />
                                </button>
                            </Col>
                        </Row>
                    </div>
    
                    <Nav className="mr-auto ml-auto" navbar>
    
                        <NavItem onClick={() => tryScroll('archk-home-demo')}>
                            <NavLink to="/?view=demo" tag={Link}>
                                <span className="nav-link-inner--text font-weight-bold">
                                    <i className="fas fa-video mr-2 text-warning " /> 
                                    Demo
                                </span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to="/pricing" tag={Link}>
                                <span className="nav-link-inner--text font-weight-bold">
                                    Pricing
                                </span>
                            </NavLink>
                        </NavItem>
                        
                        <NavItem onClick={() => tryScroll('archk-home-search')}>
                            <NavLink to="/?view=search" tag={Link}>
                                <span className="nav-link-inner--text font-weight-bold">Search Institutions</span>
                            </NavLink>
                        </NavItem>
    
                        <NavItem>
                            <NavLink href={`${keys.CLIENT_URL}/auth/register`}>
                                <span className="nav-link-inner--text font-weight-bold">Free Trial</span>
                            </NavLink>
                        </NavItem>
    
                    </Nav>
    
                    <hr className="d-lg-none" />
                    
                    <Nav className="align-items-lg-center ml-lg-auto" navbar>
                        
                        <NavItem className="d-non d-lg-block ml-lg-4">
                            <a href={`${keys.CLIENT_URL}/auth/login`} className="btn btn-success btn-block" style={{minWidth: 170}}>
                                <span className="nav-link-inner--text">Sign In</span>
                                <span className="btn-inner--icon"><i className="fas fa-sign-in-alt ml-2" /></span>
                            </a>
                        </NavItem>
                    </Nav>
    
                </UncontrolledCollapse>
            </Container>
        </Navbar>
    )
}

export default NavbarDesktop
