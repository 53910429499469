import keys from 'keys';
import { Helmet } from 'react-helmet';

import { Container, Row, Col } from 'reactstrap';
import A from 'components/markup/links/A';

import Wallet from './images/Wallet.svg';

const Pricing = () => (

    <div id="page-home" style={{overflow: 'hidden'}}>

        <Helmet>
            <title>{`Zap Reports Pricing`}</title>
            <meta name="description" content="Zap Reports Pricing" />
        </Helmet>

        <div className="header animated-hero py-7">

            <Container className="py-5" >
                <Row>
                    <Col lg={8} className="align-self-center">
                        <h1 className="display-2 text-uppercase text-white text-italic mb-4" style={{textShadow: '1px 1px 1px #777'}}>
                            <i className="fas fa-check-circle  " /> Simple & <b className="text-underline">Transparent</b> Pricing For Your Firm
                        </h1>
                        <p className="text-sm text-white" >No Contracts, No Monthly Minimums. <b className="text-underline d-block mt--2">You Only Pay When We Get You Documents</b></p>

                    </Col>
                    <Col lg={4} className="align-self-center">
                        <img src={Wallet} alt="..." className="w-100 pl-4 mb-6" />
                    </Col>
                </Row>
            </Container>

        </div>

        <div className="bg-white pb-9 pt-8 header">
            <Container >

                <h4 className="display-1 text-italic mb-3">Zap Reports Pricing</h4>
                <p className="mb-6">Our pricing is built on a win/win model - you are never charged until we can hand you over documents. There is no cost to add clients to Zap Reports, no cost if your clients fail to connect their accounts, and no monthly minimums to meet or contracts to sign.</p>

                <Row>
                    <Col md={3} className="py-4 mb-5 mb-md-4">
                        <h4 className="display-4" style={{height: 100}}>Financial</h4>
                        <p className="mt--7 mt-md-0">Unlimited connections to financial accounts for up to 90 days covering bank accounts, cash app, venmo, chime, etc.</p>
                        <div className="my-5"> <p className="display-2 d-inline">$25</p> <small>Per User</small></div>
                        <div className="text-"><A href={`${keys.CLIENT_URL}/auth/register`} className="btn btn-info">Get Started</A></div>
                    </Col>
                    <Col md={3} className="py-4 mb-5 mb-md-4">
                        <h4 className="display-4" style={{height: 100}}>Employment</h4>
                        <p className="mt--7 mt-md-0">Pay Stubs and records from employers, Uber, Lyft, DoorDash, Unemployment, Social Security, and More.</p>
                        <div className="my-5"> <p className="display-2 d-inline">$25</p> <small>Per User</small></div>
                        <div className="text-"><A href={`${keys.CLIENT_URL}/auth/register`} className="btn btn-info">Get Started</A></div>
                    </Col>
                    <Col md={3} className="border py-4 mb-5 mb-md-4 rounded border-success">
                        <h4 className="display-4" style={{height: 100}}>Financial + Employment</h4>
                        <p className="mt--7 mt-md-0">Automatic discounts kick in when your clients link both Financial and Employments accounts.</p>
                        <div className="my-5 position-relative"> 
                            <p className=" text-sm text-danger text-strike-through" style={{position: 'absolute', top: 0, left: 85}}>$50</p>
                            <p className="display-2 d-inline">$40</p> <small>Per User</small>
                        </div>
                        <div className="text-"><A href={`${keys.CLIENT_URL}/auth/register`} className="btn btn-success btn-block">Get Started</A></div>
                    </Col>
                    <Col md={3} className="py-4 mb-5 mb-md-4">
                        <h4 className="display-4" style={{height: 100}}>Auto Policies</h4>
                        <p className="mt--7 mt-md-0">If you need auto policies look no further. Your clients can get their ID cards and Declaration Pages Instantly.</p>
                        <div className="my-5"> <p className="display-2 d-inline">$5</p> <small>Per Policy</small></div>
                        <div className="text-"><A href={`${keys.CLIENT_URL}/auth/register`} className="btn btn-info ">Get Started</A></div>
                    </Col>
                </Row>

            </Container>
        </div>

        <div className="py-6 pb-6 header">
            <Container className="text-center py-6">
                <h4 className="mb-0 display-4 text-uppercase text-italic">Are you ready to revolutionize your firm?</h4>
                <p>Get started by clicking the button below.</p>
                <a href={keys.CLIENT_URL + '/auth/register'} className="btn btn-info btn-lg w-50">SIGN UP NOW</a>
            </Container>
        </div>

    </div>
)

export default Pricing