/*
Documentation

this is the wrapping component for all Legal routes
if a user is already known and logged in it will redirect
them off to their destination

if not destination is know we will redirect to /dashboard

*/

import { Route, Switch } from "react-router-dom";
import routes from "../layout/routes";

import Header from '../../site/layout/Header/Desktop'
import Footer from '../../site/layout/Footer'

const Legal = () => (
    <div className="main-conten" >

        <Header />

        <Switch>
            {routes.map((prop, key) => (
                <Route
                    path={`/legal${prop.path}`}
                    component={prop.component}
                    key={key}
                />
            ))}
        </Switch>

        <Footer />
    </div>
)

export default Legal