/*
Documentation

this is the wrapping component for all Legal routes
if a user is already known and logged in it will redirect
them off to their destination

if not destination is know we will redirect to /dashboard

*/

import { Route, Switch } from "react-router-dom";
import routes from "../layout/routes";

const Legal = () => (
    <div className="main-conten" >
        <Switch>
            {routes.map((prop, key) => (
                <Route
                    path={`/faq/${prop.path}`}
                    component={prop.component}
                    key={key}
                />
            ))}
        </Switch>
    </div>
)

export default Legal