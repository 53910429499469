import Disclaimer from '../pages/Disclaimer';
import Terms from '../pages/Terms';
import Privacy from '../pages/Privacy';

const routes = [

    {
        path: "/disclaimer",
        component: Disclaimer,
    },
    {
        path: "/privacy-policy",
        component: Privacy,
    },
    
    {
        path: "/terms-of-service",
        component: Terms,
    },
    {
        path: "",
        component: Terms,
    },

]

export default routes;