import keys from 'keys';
import { Row, Col, Card, CardHeader, CardTitle, CardFooter, CardBody, Badge } from 'reactstrap';

import Docs from 'components/system/Docs';

const DocsPage = () => (
    <Docs 
    settings={{
        logo: keys.LOGO,
        lastUpdated: '11/8/2021'
    }}
    tabs={[
        {
            name: 'Getting Started',
            content: (
                <div>
                    <Row>
                        <Col lg={6}>
                        <p>The Zap Reports API is organized around REST. Our API has predictable resource-oriented URLs and functions similarly to most modern day APIs.</p>
                    <p> Authentication is handled via an API Key and Secret and all data is returned in a standardized format.</p>
                    <p>At its current stage the Zap Reports API supports only GET, POST, and Patch requests.</p>
                        </Col>
                    </Row>
                </div>
            )
        },
        {
            name: 'Base URL',
            content: (
                <div>
                     <Row>
                        <Col md={6}>
                            <p className="mb-0">All requests to the API start with the base url shown here. </p>
                            <p>In addition, all requests require a valid API pair to be sent in the headers of a request serving as your method of authentication.</p>
                        </Col>

                        <Col md={6}>
                            <Card className="not-sticky">
                                <CardHeader>
                                    <CardTitle className="mb-0">PRODUCTION URL</CardTitle>
                                </CardHeader>
                                <CardFooter>
                                    <p className="mb-0">https://api.zapreports.com</p>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                    <p className="mb-0 text-white">PRODUCTION EXAMPLE</p>
                            <div className="mb-3"><code>POST https://api.zapreports.com/v1/connect/contacts</code></div>
                </div>
            )
        },
        {
            name: 'Authentication',
            content: (
                <div>
                    <Row>
                        <Col lg={6}>
                        <p>To validate with the the Zap Reports API, requests will be checked for valid credentials sent in the headers of the request. Headers must contain an API set containing your API Key and API Secret.</p>
    
                        <p>All Secret keys are protected via a one way hash, if you lose your API secret you must roll it from your Zap Reports Dashboard.</p>
    
                        <p>If a request is made either leaving out this key pairing or with incorrect values a 403 FORBIDDEN status code will be returned</p>
                        </Col>
                        <Col lg={6}>
                            <Card>
                                <CardHeader>
                                    <CardTitle className="mb-0">HEADERS</CardTitle>
                                </CardHeader>
                                <CardFooter>
<pre>{`{
    "api-key": {your-key},
    "api-secret": {your-secret},
}`}</pre>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )
        },
        {
            name: 'Rate Limiting',
            content: (
                <div>
                    <p>Requests to the Zap Reports API are limited to 120 per minute (roughly 2 per second). If the rate limit is reached a <b>429 status code</b> will be returned.</p>
                    <p>NOTE that this limit exists across all endpoints and resources.</p>
                </div>
            )
        },
        {
            name: 'Contacts', 
            content: (
                <div>
                    <Row>
                        <Col lg={5}>
                            <p>Contacts are the core resources needed to connect to zap reports. Everything runs through a request to either find or create a contact and then a POST request to to invite the contact to pull their needed documents.</p>
    
                            <p>Note that simply creating a contact will not give them permissions to pull their documents. If a contact has not yet had their documents requested the portal will deny them access. This is explained in more depth under the <code>/v1/connect/contacts/:_id/request </code>endpoint.</p>
                        </Col>
                        <Col lg={7}>
                            <Card>
                                <CardHeader>
                                    <CardTitle className="mb-0">ENDPOINTS</CardTitle>
                                </CardHeader>
    
                                <CardFooter>
                                    <code className="d-block mb-2"><Badge color="success">GET:</Badge> /v1/connect/contacts/:_id</code>
                                    <code className="d-block mb-2"><Badge color="success">GET:</Badge> /v1/connect/contacts/email/:email</code>
                                    <code className="d-block mb-2"><Badge color="success">GET:</Badge> /v1/connect/contacts/phone/:phone</code>
                                    <code className="d-block mb-2"><Badge color="warning">POST:</Badge> /v1/connect/contacts</code>
                                    <code className="d-block mb-2"><Badge color="warning">POST:</Badge> /v1/connect/contacts/:_id/request</code>
                                    <code className="d-block mb-2"><Badge color="info">PATCH:</Badge> /v1/connect/contacts/:_id</code>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
    
                </div>
            ),
            tabs: [
                { 
                    name: 'Properties',
                    content: (
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Property</th>
                                        <th>Type</th>
                                        <th>Values</th>
                                    </tr>
                                </thead>
    
                                <tbody>
                                    <tr>
                                        <td>_id</td>
                                        <td>ID</td>
                                        <td>A unique identifier for the contact</td>
                                    </tr>
                                    <tr>
                                        <td>company</td>
                                        <td>ID</td>
                                        <td>A unique ID of the company the contact belongs to. This will be your company ID</td>
                                    </tr>
                                    <tr>
                                        <td>given_name</td>
                                        <td>string</td>
                                        <td>The contact's first name</td>
                                    </tr>
                                    <tr>
                                        <td>family_name</td>
                                        <td>string</td>
                                        <td>The contact's last name</td>
                                    </tr>
                                    <tr>
                                        <td>display_name</td>
                                        <td>string</td>
                                        <td>The contact's full name</td>
                                    </tr>
    
                                    <tr>
                                        <td>email</td>
                                        <td>string</td>
                                        <td>The email of the contact. Must be a valid email address</td>
                                    </tr>
                                    <tr>
                                        <td>phone</td>
                                        <td>string</td>
                                        <td>The phone number of a contact formatted as +12223334444. Must be a valid phone number</td>
                                    </tr>
                                
                                    <tr>
                                        <td>report_status</td>
                                        <td>string</td>
                                        <td>null, requested, in progress, finished</td>
                                    </tr>
                                
                                    <tr>
                                        <td>access_code</td>
                                        <td>string</td>
                                        <td>An access code required by the contact to pull reports in their portal.</td>
                                    </tr>
                                    <tr>
                                        <td>integrations</td>
                                        <td>array</td>
                                        <td>An array of objects representing custom fields for outbound integrations.</td>
                                    </tr>
    
                                    <tr>
                                        <td>deleted</td>
                                        <td>boolean</td>
                                        <td>true or false</td>
                                    </tr>
                                   
                                    <tr>
                                        <td>updated_at</td>
                                        <td>number</td>
                                        <td>A unix timestamp of when the contact was last updated.</td>
                                    </tr>
                                    <tr>
                                        <td>created_at</td>
                                        <td>number</td>
                                        <td>A unix timestamp of when the contact was created.</td>
                                    </tr>
                                    
                                </tbody>
                            </table>
                        </div>
                    )
                },
                { 
                    name: 'Create',
                    content: (
                        <div>
                            <Row>
                                <Col lg={6}>
                                    <code className="mb-3 d-block">POST: /v1/connect/contacts</code>
                                    <p>Returns success true of false if contact was created.</p>
                                    <p>NOTE that a contact may not share the same email or phone fields as another contact and must have either a family_name, email, or phone property for creation to be successfull.</p>
                                    <p>In implementing this example make sure to change the phone field to a real phone number. Zap Reports will reject any email or phone fields that are not valid.</p>
                                    <p className="text-info">** If you wish to also send out a request for documents immediately after creating a contact, pass the property "request": true as an additional parameter</p>
                                </Col>
                                <Col lg={6}>
<Card className="not-sticky">
    <CardHeader>
        <CardTitle className="mb-0">REQUEST</CardTitle>
    </CardHeader>
    <CardBody>
<pre>{`{
    given_name: 'John',
    family_name: 'Doe',
    email: 'johndoe@gmail.com',
    phone: '1112223333',
    request: true, 
    // contact support or see webhooks  
    // below for integration details
    integrations: []
}`}</pre>
    </CardBody>
</Card>
<Card className="not-sticky">
    <CardHeader>
        <CardTitle className="mb-0">RESPONSE</CardTitle>
    </CardHeader>
    <CardBody>
<pre>{`{ 
    success: true,
    message: [],
    user_message: [],
    api_version: '1.0',
    path: 'POST: /v1/connect/contacts',
    time: 1636392789,
    milliseconds: 222,
    data: { 
        given_name: 'john',
        family_name: 'doe',
        display_name: 'John Doe',
        report_status: 'null',
        email: 'johndoe@gmail.com',
        phone: '+11112223333'
        deleted: false,
        _id: '61895f552e103829b422e9b5',
        company: '61895f55ca365c29b8dea7a6',
        updated_at: 1636392789,
        created_at: 1636392789,
        access_code: '346688',
        integrations: [] 
    } 
}`}</pre>
    </CardBody>
</Card>
                                </Col>
                            </Row>
                           
                        </div>
                    )
                },
                { 
                    name: 'Update',
                    content: (
                        <div>
                            <Row>
                                <Col lg={6}>
                                    <code className="mb-3 d-block">PATCH: /v1/connect/contacts/:_id</code>
                                    <p>Returns success true of false if contact was updated.</p>
                                    <p>NOTE that a contact may not share the same email or phone fields as another contact.</p>
                                    <p>In implementing this example make sure to change the phone field to a real phone number. Zap Reports will reject any email or phone fields that are not valid.</p>
                                    <p className="text-warning">** If you pass a value to the "integrations" array you must pass along all values associated with the objects inside it. Do not pass just one property (or object if you have multiple integrations) as this will erase any previous integration values.</p>
                                </Col>
                                <Col lg={6}>
<Card className="not-sticky">
    <CardHeader>
        <CardTitle className="mb-0">REQUEST</CardTitle>
    </CardHeader>
    <CardBody>
<pre>{`{
    given_name: 'John',
    family_name: 'Doe',
    email: 'johndoe@gmail.com',
    phone: '1112223333',
    // contact support for custom 
    // integration details
    integrations: []
}`}</pre>
    </CardBody>
</Card>
<Card className="not-sticky">
    <CardHeader>
        <CardTitle className="mb-0">RESPONSE</CardTitle>
    </CardHeader>
    <CardBody>
<pre>{`{ 
    success: true,
    message: [],
    user_message: [],
    api_version: '1.0',
    path: 'PATCH: /v1/connect/contacts/61895f552e103829b422e9b5',
    time: 1636392789,
    milliseconds: 222,
    data: { 
        given_name: 'john',
        family_name: 'doe',
        display_name: 'John Doe',
        report_status: 'null',
        email: 'johndoe@gmail.com',
        phone: '+11112223333'
        deleted: false,
        _id: '61895f552e103829b422e9b5',
        company: '61895f55ca365c29b8dea7a6',
        updated_at: 1636392789,
        created_at: 1636392789,
        access_code: '346688',
        integrations: [] 
    } 
}`}</pre>
    </CardBody>
</Card>
                                </Col>
                            </Row>
                           
                        </div>
                    )
                },
                { 
                    name: 'Request',
                    content: (
                        <div>
                            <Row>
                                <Col lg={6}>
                                    <code className="mb-3 d-block">POST: /v1/connect/contacts/:_id/request</code>
                                    <p>Returns success true and the result of sending the request for documents.</p>
                                    <p>Upon successfully calling this endpoint a contacts "report_status" will be changed to requested. A contact will only be allowed to successfully move through the document portal if their document status is "requested" or "in progress"</p>
                                    <p>Upon signing into the portal their document status is automatically changed to "in progress"</p>
                                    <p className="text-info">**You may bypass this step by passing the property "request": true when creating a contact.</p>
                                </Col>
                                <Col lg={6}>
<Card className="not-sticky">
    <CardHeader>
        <CardTitle className="mb-0">RESPONSE</CardTitle>
    </CardHeader>
    <CardBody>
<pre>{`{ 
    success: true,
    message: [],
    user_message: [],
    api_version: '1.0',
    path: 'POST: /v1/connect/contacts/61895f552e103829b422e9b5/request',
    time: 1636393740,
    milliseconds: 670,
    data: { 
    contact: { 
        given_name: 'john',
        family_name: 'doe',
        display_name: 'John Doe',
        report_status: 'requested',
        email: 'johndoe@gmail.com',
        phone: '+11112223333'
        deleted: false,
        _id: '61895f552e103829b422e9b5',
        company: '61895f55ca365c29b8dea7a6',
        updated_at: 1636392789,
        created_at: 1636392789,
        access_code: '346688',
        integrations: [] 
    },
    event: { 
        _id: '6189630c6cdbf42a9acfa8cb',
        type: 'user',
        body: 'A request for documents was sent to the contact via text message and email.',
        contact: '6189630c1136642aa363a066',
        created_at: 1636393740,
        updated_at: 1636393740,
        company: '6189630c1136642aa363a065',
        __v: 0 
    },
    sentText: true,
    sentEmail: true,
    } 
}`}</pre>
    </CardBody>
</Card>
                                </Col>
                            </Row>
                           
                        </div>
                    )
                },
                { 
                    name: 'Find By ID',
                    content: (
                        <div>
                            <Row>
                                <Col lg={6}>
                                    <code className="mb-3 d-block">GET: /v1/connect/contacts/:_id</code>
                                    <p>Returns success true and valid data object if contact was found.</p>
                                    <p>NOTE that this response may return {`{success: true}`} without a data field if the _id passed in was not found</p>
                                </Col>
                                <Col lg={6}>
<Card className="not-sticky">
    <CardHeader>
        <CardTitle className="mb-0">RESPONSE</CardTitle>
    </CardHeader>
    <CardBody>
<pre>{`{ 
    success: true,
    message: [],
    user_message: [],
    api_version: '1.0',
    path: 'GET: /v1/connect/contacts/61895f552e103829b422e9b5',
    time: 1636393135,
    milliseconds: 196,
    data: { 
        given_name: 'john',
        family_name: 'doe',
        display_name: 'John Doe',
        report_status: 'null',
        email: 'johndoe@gmail.com',
        phone: '+11112223333'
        deleted: false,
        _id: '61895f552e103829b422e9b5',
        company: '61895f55ca365c29b8dea7a6',
        updated_at: 1636392789,
        created_at: 1636392789,
        access_code: '346688',
        integrations: [] 
    } 
}`}</pre>
    </CardBody>
</Card>
                                </Col>
                            </Row>
                           
                        </div>
                    )
                },
                { 
                    name: 'Find By Email',
                    content: (
                        <div>
                            <Row>
                                <Col lg={6}>
                                    <code className="mb-3 d-block">GET: /v1/connect/contacts/email/:email</code>
                                    <p>Returns success true and valid data object if contact was found.</p>
                                    <p>NOTE that this response may return {`{success: true}`} without a data field if the email passed in was not found</p>
                                </Col>
                                <Col lg={6}>
<Card className="not-sticky">
    <CardHeader>
        <CardTitle className="mb-0">RESPONSE</CardTitle>
    </CardHeader>
    <CardBody>
<pre>{`{ 
    success: true,
    message: [],
    user_message: [],
    api_version: '1.0',
    path: 'GET: /v1/connect/contacts/email/johndoe@gmail.com',
    time: 1636393135,
    milliseconds: 196,
    data: { 
        given_name: 'john',
        family_name: 'doe',
        display_name: 'John Doe',
        report_status: 'null',
        email: 'johndoe@gmail.com',
        phone: '+11112223333'
        deleted: false,
        _id: '61895f552e103829b422e9b5',
        company: '61895f55ca365c29b8dea7a6',
        updated_at: 1636392789,
        created_at: 1636392789,
        access_code: '346688',
        integrations: [] 
    } 
}`}</pre>
    </CardBody>
</Card>
                                </Col>
                            </Row>
                           
                        </div>
                    )
                },
                { 
                    name: 'Find By Phone',
                    content: (
                        <div>
                            <Row>
                                <Col lg={6}>
                                    <code className="mb-3 d-block">GET: /v1/connect/contacts/phone/:phone</code>
                                    <p>Returns success true and valid data object if contact was found.</p>
                                    <p>NOTE that this response may return {`{success: true}`} without a data field if the phone passed in was not found</p>
                                </Col>
                                <Col lg={6}>
<Card className="not-sticky">
    <CardHeader>
        <CardTitle className="mb-0">RESPONSE</CardTitle>
    </CardHeader>
    <CardBody>
<pre>{`{ 
    success: true,
    message: [],
    user_message: [],
    api_version: '1.0',
    path: 'GET: /v1/connect/contacts/phone/+11112223333',
    time: 1636393135,
    milliseconds: 196,
    data: { 
        given_name: 'john',
        family_name: 'doe',
        display_name: 'John Doe',
        report_status: 'null',
        email: 'johndoe@gmail.com',
        phone: '+11112223333'
        deleted: false,
        _id: '61895f552e103829b422e9b5',
        company: '61895f55ca365c29b8dea7a6',
        updated_at: 1636392789,
        created_at: 1636392789,
        access_code: '346688',
        integrations: [] 
    } 
}`}</pre>
    </CardBody>
</Card>
                                </Col>
                            </Row>
                           
                        </div>
                    )
                },

            ]
        },
        {
            name: 'Documents', 
            content: (
                <div>
                    <Row>
                        <Col lg={5}>
                            <p>After a contact links their accounts, documents will be available for download on the dashboard or via webhook (explained below). </p>
                            <p>They can also be refreshed to get updated information in the form of new documents generated.</p>
                        </Col>
                        <Col lg={7}>
                            <Card>
                                <CardHeader>
                                    <CardTitle className="mb-0">ENDPOINTS</CardTitle>
                                </CardHeader>
    
                                <CardFooter>
                                    <code className="d-block mb-2"><Badge color="warning">POST:</Badge> /v1/connect/documents/refresh</code>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
    
                </div>
            ),
            tabs: [
                { 
                    name: 'Refresh',
                    content: (
                        <div>
                            <Row>
                                <Col lg={6}>
                                    <code className="mb-3 d-block">POST: /v1/connect/document/refresh</code>
                                    <p>Returns success true of false if documents were refreshed.</p>
                                    <p>For convenience contacts can be identified by email, phone, or a Zap Reports contact id.</p>
                                    <p>Note that if searching by email, the identifier sent must be <b className="text-warning">trimmed and in lowercase format.</b> </p>
                                    <p>If searching by phone, the identifier must be in the format <b className="text-warning">+15555555555</b></p>
                                    <p className="text-info">** Requests to refresh document are limited to 1 successful request every 24 hours</p>
                                </Col>
                                <Col lg={6}>
<Card className="not-sticky">
    <CardHeader>
        <CardTitle className="mb-0">REQUEST</CardTitle>
    </CardHeader>
    <CardBody>
<pre>{`{
    "identifier": "email | phone | id"
}`}</pre>
    </CardBody>
</Card>
<Card className="not-sticky">
    <CardHeader>
        <CardTitle className="mb-0">RESPONSE SUCCESS</CardTitle>
    </CardHeader>
    <CardBody>
<pre>{`{
    "success": true,
    "message": [],
    "user_message": [],
    "api_version": "1.0",
    "path": "POST: /v1/connect/documents/refresh",
    "time": 1651679424,
    "milliseconds": 1269,
    "data": {
        // how many documents were refreshed
        "document_number": 3 
    }
}`}</pre>
    </CardBody>
</Card>
<Card className="not-sticky">
    <CardHeader>
        <CardTitle className="mb-0">RESPONSE RATE LIMITED</CardTitle>
    </CardHeader>
    <CardBody>
<pre>{`{
    "success": false,
    "user_message": [],
    "api_version": "1.0",
    "path": "POST: /v1/connect/documents/refresh",
    "time": 1651680022,
    "milliseconds": 330,
    "data": null,
    "message": [
        "Rate limit exceeded of 1 refresh every 24 hours, this contact can have updated documents requested again at {unix timestamp}"
    ],
}`}</pre>
    </CardBody>
</Card>
                                </Col>
                            </Row>
                           
                        </div>
                    )
                },
            ]
        },
        {
            name: 'Webhooks', 
            content: (
                <div>
                    <Row>
                        <Col lg={5}>
                            <p>Webhooks allow your servers to receive information back from Zap Reports as it becomes available.</p>
                            <p>Currently the Zap Reports API supports the posting of PDF documents back to your servers in a <b className="text-white">base64</b> format contained on the property "filedata".</p>
                        </Col>
                        <Col lg={7}>
                            <Card>
                                <CardHeader>
                                    <CardTitle className="mb-0">ENDPOINTS</CardTitle>
                                </CardHeader>
    
                                <CardFooter>
                                    <code className="d-block mb-2"><Badge color="warning">POST:</Badge> /v1/connect/webhooks</code>
                                    <code className="d-block mb-2"><Badge color="info">PATCH:</Badge> /v1/connect/webhooks</code>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
    
                </div>
            ),
            tabs: [
                { 
                    name: 'Properties',
                    content: (
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Property</th>
                                        <th>Type</th>
                                        <th>Values</th>
                                    </tr>
                                </thead>
    
                                <tbody>
                                    <tr>
                                        <td>name</td>
                                        <td>string</td>
                                        <td>The name of the webhook set up</td>
                                    </tr>
                                    <tr>
                                        <td>enabled</td>
                                        <td>boolean</td>
                                        <td>If set to false this webhook will not fire.</td>
                                    </tr>
                                    <tr>
                                        <td>fields</td>
                                        <td>object</td>
                                        <td>{`{api_endpoint: 'endpoint to send webhooks to'}`}</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    )
                },
                { 
                    name: 'System Link',
                    content: (
                        <div>
                        <Row>
                            <Col lg={6}>
                                <p>To be able to link documents sent to your servers to the appropriate party Zap Reports supports a <b className="text-white">custom set of values</b> that can be passed to a contact when they are created.</p>
                                <p>When Zap Reports sends a webhook to your specified endpoint <b className="text-white">these values will be passed along</b> with the webhook so you can match the document to the correct party.</p>
   
                            </Col>
                            <Col lg={6}>
<Card className="not-sticky">
    <CardHeader>
        <CardTitle className="mb-0">Example Webhook Payload</CardTitle>
    </CardHeader>
    <CardBody>
<pre>{`{
    // base64
    filedata: "xlcgoolJUVPRgo=",
    // string
    filename: "test.pdf",
    {
        ...yourCustomValues
    }
}`}</pre>
    </CardBody>
</Card>
                                </Col>
                        </Row>
                       
                    </div>
                    )
                },
                { 
                    name: 'Full Example',
                    content: (
                        <div>
                        <Row>
                            <Col lg={6}>
                                <p>As an example of the entire process, we will start by creating a contact via API with a set of custom values passed along.</p>
                                <p>The name of the integration should always stay as <b className="text-white">"documents"</b> to accomodate a standard webhook.</p>
                                <p>Note the values array sent, this is a set of key value pairs that you can use to send any identifying information you will need to read later on.</p>
                                <hr />
                                <p className="text-info">** The integrations property is set as an array to accommodate future changes in the API. Currently it supports one object with the name "documents" </p>
                            </Col>
                            <Col lg={6}>
<Card className="not-sticky">
    <CardHeader>
        <CardTitle className="mb-0"> <code className="d-block">POST: /v1/connect/contacts</code></CardTitle>
    </CardHeader>
    <CardBody>
<pre>{`{
    given_name: 'John',
    family_name: 'Doe',
    email: 'johndoe@gmail.com',
    phone: '1112223333',
    integrations: [{
        name: 'documents',
        values: {
            contact_id: '123'
            unique_identifier: 'abc'
        }
    }]
}`}</pre>
    </CardBody>
</Card>
                            </Col>
                            <Col lg={6}>
                                <p>Once this contact has linked their accounts, documents will be sent via webhook with the following format.</p>
                            </Col>
                            <Col lg={6}>
<Card className="not-sticky">
    <CardHeader>
        <CardTitle className="mb-0"> <code className="d-block">POST: https://your-server.com/webhooks</code></CardTitle>
    </CardHeader>
    <CardBody>
<pre>{`{
    filedata: "xlcgoolJUVPRgo=",
    filename: "test.pdf",
    contact_id: '123',
    unique_identifier: 'abc'
}`}</pre>
    </CardBody>
</Card>
                            </Col>
                        </Row>
                       
                    </div>
                    )
                },

                { 
                    name: 'Create Webhook',
                    content: (
                        <div>
                        <Row>
                            <Col lg={6}>
                                <code className="mb-3 d-block">Post: /v1/connect/webhooks</code>
                                <p>This endpoint must be called to begin receiving webhooks from Zap Reports to your servers.</p>
                                <p>Returns success true and information about the endpoint if successful.</p>
                                <p>Should you ever need to change this webhook in the future see updating a webhook below.</p>
                            </Col>
                            <Col lg={6}>
<Card className="not-sticky">
    <CardHeader>
        <CardTitle className="mb-0">REQUEST</CardTitle>
    </CardHeader>
    <CardBody>
<pre>{`{
    "name": "documents",
    "api_endpoint": "https://your-server.com/webhooks"
}`}</pre>
    </CardBody>
</Card>
<Card className="not-sticky">
    <CardHeader>
        <CardTitle className="mb-0">RESPONSE</CardTitle>
    </CardHeader>
    <CardBody>
<pre>{`{
    "success": true,
    "message": [],
    "user_message": [],
    "api_version": "1.0",
    "path": "POST: /v1/connect/webhooks",
    "time": 1645891755,
    "milliseconds": 251,
    "data": [
        {
            "name": "documents",
            "enabled": true,
            "_id": "621a50abb74a9104c69be4e7",
            "fields": {
                "api_endpoint": "https://your-server.com/webhooks"
            }
        }
    ]
}`}</pre>
    </CardBody>
</Card>
                                </Col>
                        </Row>
                       
                    </div>
                    )
                },
                { 
                    name: 'Update Webhook',
                    content: (
                        <div>
                        <Row>
                            <Col lg={6}>
                                <code className="mb-3 d-block">PATH: /v1/connect/webhooks</code>
                                <p>Returns success true and information about the endpoint if successful.</p>
                                <p>The only fields that can be passed to this endpoint are "enabled" and "api_endpoint".</p>
                                <p>Should you wish to ever disable a webhook use this endpoint passing {`{enabled: false}`} in the body of the request</p>
                            </Col>
                            <Col lg={6}>
<Card className="not-sticky">
    <CardHeader>
        <CardTitle className="mb-0">REQUEST</CardTitle>
    </CardHeader>
    <CardBody>
<pre>{`{
    "name": "documents",
    "enabled": false,
    "api_endpoint": "https://your-server.com/webhooks/v2"
}`}</pre>
    </CardBody>
</Card>
<Card className="not-sticky">
    <CardHeader>
        <CardTitle className="mb-0">RESPONSE</CardTitle>
    </CardHeader>
    <CardBody>
<pre>{`{
    "success": true,
    "message": [],
    "user_message": [],
    "api_version": "1.0",
    "path": "POST: /v1/connect/webhooks",
    "time": 1645891755,
    "milliseconds": 251,
    "data": [
        {
            "name": "documents",
            "enabled": false,
            "_id": "621a50abb74a9104c69be4e7",
            "fields": {
                "api_endpoint": "https://your-server.com/webhooks/v2"
            }
        }
    ]
}`}</pre>
    </CardBody>
</Card>
                                </Col>
                        </Row>
                       
                    </div>
                    )
                },
                { 
                    name: 'Webhook Signing',
                    content: (
                        <div>
                        <Row>
                        

                            <Col lg={6}>
                                <p>To verify a webhook was sent from Zap Reports all webhooks sent will contain a field called "zap-reports-signature" in the header of the request.</p>
                                <p>This property should be matched against the Webhook Signing Secret that was displayed when you created or rolled your last set of API keys.</p>
                            </Col>
                            <Col lg={6}>
<Card className="not-sticky">
    <CardHeader>
        <CardTitle className="mb-0 text-success"><code className="d-block">POST: https://your-server.com/webhooks</code></CardTitle>
    </CardHeader>
    <CardBody>
<pre>{`
HEADERS:
{
    ...
    'zap-reports-signature': 'DW6aQptHrlPgUdKdllqzS4UqFmwDBQ',
}

BODY:
{
    filedata: "xlcgoolJUVPRgo=",
    filename: "test.pdf",
    contact_id: '123',
    unique_identifier: 'abc'
}
`}</pre>
    </CardBody>
</Card>

                                </Col>
                        </Row>
                       
                    </div>
                    )
                },
            ]
        }
    ]}
        
    
    />
)

export default DocsPage;