import { useState, useCallback, useEffect } from 'react'
import { Helmet } from 'react-helmet';

import { Container, Row, Col, Navbar, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';

import Hero from './images/Hero.png'
import Step1 from './images/Step1.png'
import Step2 from './images/Step2.png'
import Step3 from './images/Step3.png'
import Building1 from './images/Building1.png'
import Building2 from './images/Building2.png'
import Background1 from './images/Background1.png'

import keys from 'keys';

import Search from '../Search';

import Circle from 'components/markup/loading/Circle'

import _total_connections from '_functions/total_connections'
import { formatNumber } from 'utils/currency'

import './styles/index.scss'

const LandingPage1 = () => {

    const [connections, setConnections] = useState({
        aggregator_1: 0, 
        aggregator_2: 0, 
        aggregator_3: 0,
        yesterday_aggregator_1: 0, 
        yesterday_aggregator_2: 0, 
        yesterday_aggregator_3: 0,
    })

    const fetchConnections = useCallback(async () => {

        const data = await _total_connections.find();
        if(data.data) {
            const today       = data.data[0] ? data.data[0] : {};
            setConnections({
                aggregator_1    : today.aggregator_1 ? today.aggregator_1 : 0,
                aggregator_2    : today.aggregator_2 ? today.aggregator_2 : 0,
                aggregator_3    : today.aggregator_3 ? today.aggregator_3 : 0,
                date            : today.created_at ? today.created_at : 0,
            })
        }

    }, [])

    const totalConnections = connections.aggregator_1 + connections.aggregator_2 + connections.aggregator_3;
    const registerLink = keys.CLIENT_URL + '/auth/register?p=l1'

    useEffect(() => {
        fetchConnections();

    }, [fetchConnections])

    return (

        <div id="page-landing-1" style={{overflow: 'hidden'}}>

            <Helmet>
                <title>{`Zap Reports`}</title>
                <meta name="description" content="Zap Reports" />
            </Helmet>

            <div className="navigation py-5 d-none d-md-block">
                <Container className="container-inner">
                    <Row>
                        <Col md={6} className="align-self-center">
                            <img src={keys.LOGO} style={{width: 250}} alt="Zap Reports" />
                        </Col>
                        <Col md={6} className="align-self-center text-right">
                            <a href={registerLink} className="btn btn-outline-success">
                                Sign Up
                            </a>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="navigation py-5 d-block d-md-none">
                <Container className="container-inner text-center">
                    <img src={keys.LOGO} style={{width: 250}} alt="Zap Reports" />

                    <a href={registerLink} className="btn btn-outline-success btn-block mt-4">
                                Sign Up
                            </a>
                </Container>
            </div>

            <div className="hero header bg-info py-7">
                <Container className="py-0" >
                    <Row>
                        <Col lg={5} className="align-self-center">
                            <h1 className="display-2 text-white mb-4">
                                Fast, Efficient, and Easy Document Collection For Lawyers
                            </h1>
                            <p className="my-5 text-white" >Financial Documents In Seconds</p>

                            <a href={registerLink} className="btn btn-outline-neutral">Try It For Free</a>
                            
                        </Col>
                        <Col lg={7} className="align-self-center">
                            <img src={Hero} alt="..." className="pl-4 mt-6 mt-lg-0" />
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="header bg-white py-7">
                <Container className="container-inner">
                    <h2 className="display-3 text-info mb-5 text-center">
                        Watch How Zap Reports Works
                    </h2>

                    <div className="video-container" style={{overflow: 'visible'}}>
                        <iframe 
                            style={{zIndex: 2}} 
                            src="https://player.vimeo.com/video/680982460?h=ecd2ff6c48&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" 
                            frameBorder="0" 
                            allow="autoplay; fullscreen; picture-in-picture" 
                            allowFullScreen 
                            title="Zap Reports Walkthrough.mp4"
                        />
                    </div>

                    <p className="text-muted text-italic mb-5 mt--3">3 Minute Watch</p>

                    <a href={registerLink} className="btn btn-outline-warning btn-bold btn-block">
                        Start Your Free Trial Now
                    </a>
                </Container>
            </div>

            <div className="header py-4 bg-white">
                <Container>
                    <hr />
                </Container>
            </div>

            <div className="header bg-white py-7">
                <Container className="container-inner">
                    <Row>
                        <Col lg={1}></Col>
                        <Col lg={10}>
                            <h2 className="display-3 text-warning mb-4 text-center">
                                Give Your Firm A Competitive Edge by Making Document Collection Easy for You and Your Clients.
                            </h2>

                            <p className="lead text-center text-dark font-weight-bold">Reports that make you smile</p>
                        </Col>
                        <Col lg={1}></Col>
                    </Row>
                </Container>
            </div>
           
            <div className="header bg-white pt-0 pb-7">
                <Container className="container-inner">
                    <Row>
                        <Col lg={5} className="align-self-center">
                            <h2 className="display-3 text-warning mb-2">Step 1</h2>
                            <p className="text-warning mb-6">Request documents from clients with a single click</p>
                            <a href={registerLink} className="btn btn-bold btn-outline-warning px-5 mb-6 mb-lg-0">
                                Try It Risk Free
                            </a>
                        </Col>
                        <Col lg={7} className="align-self-center">
                            <img src={Step1} alt="Step 1" className="w-75" />
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="header bg-white py-7">
                <Container className="container-inner">
                    <Row>
                        
                        <Col lg={5} className="align-self-center order-xs-1 order-lg-2">
                            <div className="pl-0 pl-md-5">
                                <h2 className="display-3 text-info mb-2 ">Step 2</h2>
                                <p className="text-info mb-6">Clients securely provide credentials to access their institutions</p>
                                <a href={registerLink} className="btn btn-outline-info btn-bold px-5 mb-6 mb-lg-0">
                                    Make Collection Easy
                                </a>
                            </div>
                        </Col>
                        <Col lg={7} className="align-self-center order-xs-2 order-lg-1 ">
                            <img src={Step2} alt="Step 2" className="w-100" />
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="header bg-white pb-8 pt-9">
                <Container className="container-inner">
                    <Row>
                        <Col lg={5} className="align-self-center">
                            <h2 className="display-3 text-info-dark mb-2">Step 3</h2>
                            <p className="text-info-dark mb-6">Paystubs, statements, and transaction histories are generated and sent to your Law Firm</p>
                            <a href={registerLink} className="btn btn-outline-info btn-outline-info-dark btn-bold px-5 mb-6 mb-lg-0">
                                Automate Your Process
                            </a>
                        </Col>
                        <Col lg={7} className="align-self-center">
                            <img src={Step3} alt="Step 3" className="w-100" />
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="header py-7">
                <Container className="container-inner">
                    <h2 className="display-3 text-info mb-5 text-center">
                        Get Connected
                    </h2>

                    <Row>
                        <Col lg={7} className="align-self-center">
                            <p className='display-4 font-weight-bold mb-0 text-center text-dark mb--3'>Give It A Try</p>
                            <div className="text-center"><i className="fas fa-caret-down display-2" /> </div>
                            <Search />
                        </Col>
                        <Col lg={5} className="align-self-center">
                            <div className='pl-0 pl-md-5'>
                            {connections.aggregator_1 && connections.aggregator_2 && connections.aggregator_3 ? (
                                <div>

                                    <div className="mb-7">
                                        <h3 className="display-2 text-capitalize text-warning mt-6 mt-lg-0">
                                            {Math.floor(totalConnections / 1000)}K+ CONNECTIONS
                                        </h3>
                                        <p className="text-warning"> Including Pay Stubs & Bank Statements</p>
                                    </div>

                                    <div className="mb-7">
                                        <img src={Building1} alt="building icon 1" style={{width: 50}} className="mb-3" />
                                        <h3 className="display-1 text-capitalize text-teal">
                                            {formatNumber(connections.aggregator_3)}
                                        </h3>
                                        <p className="text-dark font-weight-bold">Supported Employers</p>
                                    </div>
                                    <div>
                                        <img src={Building2} alt="building icon 2" style={{width: 50}} className="mb-3" />
                                        <h3 className="display-1 text-capitalize text-green">
                                            {formatNumber(connections.aggregator_1 + connections.aggregator_2)}
                                        </h3>
                                        <p className="text-dark font-weight-bold">Connected Financial Institutions</p>

                                    </div>

                                </div>
                            ) : (
                                <div style={{width: 40}}><Circle /></div>
                            )}

                            </div>
                        </Col>
                    </Row>

                    <a href={registerLink} className="btn btn-outline-warning btn-block mt-6 btn-bold">
                        Try Zap Reports For Free
                    </a>

                </Container>
            </div>
          
            <div className="header py-7" style={{backgroundImage: `url("${Background1}")`, backgroundSize: 'cover', backgroundPosition: 'top'}}>
                <Container className="container-inner">
                    <h2 className="display-3 text-white mb-4 text-center">
                        Accessibility <i className="fas fa-check mr-2 " />
                    </h2>

                    <Row>

                        <Col md={4} className="align-self-center">
                            <ul className="ml-0 mb-0 pl-0">
                                <li className="list-style-none text-white"><i className="fas fa-check-double mr-2 " /> Financial Institutions</li>
                                <li className="list-style-none text-white"><i className="fas fa-check mr-2 " /> Cash App</li>
                                <li className="list-style-none text-white"><i className="fas fa-check mr-2 " /> Venmo</li>
                                <li className="list-style-none text-white"><i className="fas fa-check mr-2 " /> PayPal</li>
                                <li className="list-style-none text-white"><i className="fas fa-check mr-2 " /> Zelle</li>
                                <li className="list-style-none text-white"><i className="fas fa-check mr-2 " /> Mortgages</li>
                            </ul>
                        </Col>

                        <Col md={4} className="align-self-center">
                            <ul className="ml-0 mb-0 pl-0">
                                <li className="list-style-none text-white"><i className="fas fa-check mr-2 " /> Payroll</li>
                                <li className="list-style-none text-white"><i className="fas fa-check mr-2 " /> Pay Stubs</li>
                                <li className="list-style-none text-white"><i className="fas fa-check mr-2 " /> Unemployment</li>
                                <li className="list-style-none text-white"><i className="fas fa-check mr-2 " /> Uber</li>
                                <li className="list-style-none text-white"><i className="fas fa-check mr-2 " /> Lyft</li>
                                <li className="list-style-none text-white"><i className="fas fa-check mr-2 " /> Doordash</li>
                            </ul>
                        </Col>

                        <Col md={4} className="align-self-center">
                            <ul className="ml-0 mb-0 pl-0">
                                <li className="list-style-none text-white"><i className="fas fa-check mr-2 " /> Retirement</li>
                                <li className="list-style-none text-white"><i className="fas fa-check mr-2 " /> Investments</li>
                                <li className="list-style-none text-white"><i className="fas fa-check mr-2 " /> Automotive</li>
                                <li className="list-style-none text-white"><i className="fas fa-check mr-2 " /> Social Security</li>
                                <li className="list-style-none text-white"><i className="fas fa-check mr-2 " /> Disability</li>
                                <li className="list-style-none text-white"><i className="fas fa-check mr-2 " /> Most Major Institutions</li>
                            </ul>
                        </Col>

                    </Row>

                </Container>
            </div>

            <div className="header bg-white py-7">
                <Container className="container-inner">
                    <div className="position-relative">
                        <span className="quote-mark quote-mark-left text-warning">“</span>
                        <p className="quote text-warning">Getting documents for my practice has always been a struggle. With Zap Reports, I've reduced my time to file to just 15 days from a verbal commitment, saved on staff time, and I no longer have to harass clients for documents and that feels great!</p>
                        <span className="quote-mark quote-mark-right text-warning" style={{transform: "matrix(-1, 0, 0, -1, 0, 0)"}}>“</span>
                    </div>
                  

                    <p className="text-warning">David Ienna <b>- President Fairmax Law</b></p>
                </Container>
            </div>

            <div className="header bg-teal py-7">
                <Container className="container-inner">
                    <Row>
                        <Col lg={6}>
                            <h2 className="display-3 text-white mb-4">No Hidden Fees</h2>
                            <p className="text-white text-sm mb-6">We're here to make the legal world better, not to charge you an arm and a leg. With us, you will now exactly what you'll pay every month.</p>
                        </Col>
                        <Col lg={6}>
                            <h2 className="display-3 text-white mb-4">Flexible Pricing</h2>
                            <p className="text-white text-sm mb-6">Do not worry if you have a slow month. it happens to every firm. Zap Reports only charges you for the reports hat you pull. No Monthly Minimums.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <h2 className="display-3 text-white mb-4 mb">Simple, Easy API's</h2>
                            <p className="text-white text-sm">We make it as easy as possible for developers to communicate directly to Zap Reports. Connect in hours, not days.</p>
                        </Col>
                        <Col lg={6}>
                            <h2 className="display-3 text-white mb-4">Workflow Ready</h2>
                            <p className="text-white text-sm">Zap Reports has the ability to connect to existing Case Management Platform so your documents show up instantly.</p>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="header bg-white py-7">
                <Container className="container-inner">
                    <h2 className="display-3 text-dark mb-4 text-center">Ready to Revolutionize Your Firm?</h2>
                    <a href={registerLink} className="btn btn-outline-info btn-outline-info-dark btn-block btn-bold">Try Zap Reports Risk Free</a>

                </Container>
            </div>

            <Navbar className="navbar-horizontal navbar-dark bg-dark" expand="lg" >
                <Container>
                    <NavbarBrand onClick={e => e.preventDefault()}>
                        <img
                        alt="..."
                        src={keys.LOGO}
                        />
                    </NavbarBrand>

                    <Nav className="ml-lg-auto" navbar>

                        <NavItem>
                            <NavLink className="nav-link-icon text-white" onClick={e => e.preventDefault()}>
                                <span className='text-white'>&copy; 2022 Zap Reports LLC</span>
                            </NavLink>
                        </NavItem>

                    </Nav>

                </Container>
            </Navbar>
            
        </div>
    )

}

export default LandingPage1