import { useState, useEffect, useCallback } from "react"
import { Link } from 'react-router-dom'
import { setUrlParameter, getUrlParameter } from 'utils/urls'

function isScrolledIntoView(el) {
    var rect = el.getBoundingClientRect();
    var elemTop = rect.top;
    var elemBottom = rect.bottom;

    // Only completely visible elements return true:
    var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
    // Partially visible elements return true:
    //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return isVisible;
}

const ArchkDocsSidebar = ({tabs, settings}) => {

    const [active, setActive] = useState('archk-docs-sidebar-tab-0')

    const scrollTo = useCallback((i1, i2) => {

        const div1 = document.getElementById(`archk-docs-main-content-${i1}`)
        const div2 = document.getElementById(`archk-docs-main-content-${i1}-${i2}`)

        if(div2) return div2.scrollIntoView({behavior: 'smooth'});
        if(div1) div1.scrollIntoView({behavior: 'smooth'});

    }, [])

    const onClick = useCallback((i1, i2, _id) => {
        setActive(_id)
        scrollTo(i1, i2)

        setUrlParameter('_id', _id)
        setUrlParameter('i1', i1)
        setUrlParameter('i2', i2)
    }, [scrollTo])

    const onScroll = useCallback(() => {
        let set = false;
        tabs.forEach((tab, i1) => {
            if(set === true) return
            const div = document.getElementById(`archk-docs-main-content-${i1}`)
            if(div && isScrolledIntoView(div)) {
                set = true;
                return setActive(`archk-docs-sidebar-tab-${i1}`)
            }

            if(tab.tabs && tab.tabs.length) {
                tab.tabs.forEach((tab, i2) => {
                    if(set === true) return
                    const div = document.getElementById(`archk-docs-main-content-${i1}-${i2}`)
                    if(div && isScrolledIntoView(div)) {
                        set = true;
                        return setActive(`archk-docs-sidebar-tab-${i1}-${i2}`)
                    }
    
                })
            }

        })
    }, [tabs])

    useEffect(() => {
        window.addEventListener('scroll', onScroll)
        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    }, [tabs, onScroll])

    useEffect(() => {
        window.onload = () => {
            const _id = getUrlParameter('_id')
            const i1 = getUrlParameter('i1')
            const i2 = getUrlParameter('i2')

            if(_id && i1 && i2) {
                onClick(i1, i2, _id)
            }
        }
    }, [onClick])

    return (
        <div className="archk-docs-sidebar">

            <Link to="/">
                <img 
                    className="archk-docs-sidebar-logo"
                    src={settings.logo}
                    alt='Logo'
                />
            </Link>

            <ul>
                {tabs.map((t1, i1) => {

                    const _id = `archk-docs-sidebar-tab-${i1}`;
                    return (
                        <li key={_id}>
                            <span 
                                onClick={() => onClick(i1, undefined, _id)}
                                id={_id}  
                                className={`archk-docs-sidebar-wrapper ${_id === active ? 'active' : null}`}
                            >
                                {t1.name}
                            </span>
                            {t1.tabs && t1.tabs.length ? (
                                <ul>
                                    {t1.tabs.map((t2, i2) => {

                                        const _id2 =`archk-docs-sidebar-tab-${i1}-${i2}`
                                        return (
                                            <li key={_id2}>
                                                <span 
                                                    onClick={() => onClick(i1, i2, _id2)}
                                                    id={_id2}  
                                                    className={`archk-docs-sidebar-wrapper ${_id2 === active ? 'active' : null}`}
                                                    >
                                                    {t2.name}
                                                </span>
                                            </li>
                                        )
                                    })}
                                </ul>
                            ) : null}
                        </li>
                    )
                })}

            </ul>

        </div>
    )

}

export default ArchkDocsSidebar;