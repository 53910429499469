import LOGO from 'assets/img/brand/ZapReportsLogoLong.png'
import LOGO_WHITE from 'assets/img/brand/ZapReportsLogo.png'

let keys = {
    LOGO,
    LOGO_WHITE,
    COMPANY: 'Zap Reports',
};

const HOSTNAME = window.location.hostname

//using production keys
if(HOSTNAME === 'zapreports.com') {

    keys = {

        ...keys,

        API_URL                   : 'https://api.zapreports.com',
        CLIENT_URL                : 'https://client.zapreports.com',
        SYSTEM_API_KEY            : 'architeck-58163098451',

    }

//using staging keys
} else if(HOSTNAME !== 'localhost') {

    keys = {

        ...keys,

        API_URL                   : 'https://staging.api.zapreports.com',
        CLIENT_URL                : 'https://staging.client.zapreports.com',
        SYSTEM_API_KEY            : 'architeck-946154718',

    }

//using development keys
} else {

    keys = {

        ...keys,

        API_URL                   : 'http://localhost:5004',
        CLIENT_URL                : 'http://localhost:3000',
        SYSTEM_API_KEY            : 'architeck-',


    }

}

export default keys
