import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Privacy = () => (
    <Container className="py-6">

        <Helmet>
            <title>{`Privacy Policy`}</title>
            <meta name="description" content="Privacy Policy" />
        </Helmet>


        <h1>Zap Reports - Privacy Policy</h1>
        <p className="text-sm"><strong>Effective date:</strong> September 08, 2021</p>
        
        <hr />

        <p className="text-sm">Zapp Reports LLC. (“us”, “we”, or “our”) operates the <Link to="/">https://zapreports.com</Link> website  (hereinafter referred to as the “Service”).</p>
        <p className="text-sm">This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>
        <p className="text-sm">We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.</p>
        
        
        <h2 className="mt-6">1. Definitions</h2>
        <h3>1.1 Service</h3>
        <p className="text-sm">Service means the <Link to="/">https://zapreports.com</Link> website operated by Zapp Reports LLC.</p>
        <h3>1.2 Personal Data</h3>
        <p className="text-sm">Personal Data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).</p>
        <h3>1.3 Usage Data</h3>
        <p className="text-sm">Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
        <h3>1.4 Cookies</h3>
        <p className="text-sm">Cookies are small files stored on your device (computer or mobile device).</p>
        
        <h2 className="mt-6">2. Use of Data</h2>
        <p className="text-sm">Zap Reports uses the collected data for various purposes:</p>
        <ul>
            <li>To provide and maintain the Service</li>
            <li>To notify you about changes to our Service</li>
            <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
            <li>To provide customer care and support</li>
            <li>To provide analysis or valuable information so that we can improve the Service</li>
            <li>To monitor the usage of the Service</li>
            <li>To detect, prevent and address technical issues</li>
            <li>To facilitate academic research and advance the scientific study of document collection</li>
        </ul>
        <h3>2.1 Retention of Data</h3>
        <p className="text-sm">We retain your personal data while your account is in existence or as needed to provide you Services. This includes data you or others provided to us and data generated or inferred from your use of ourServices. We will retain your information and keep your profile open until you decide to close your account. In some cases we choose to retain certain information in a depersonalized or aggregated form.</p>
        <h3>2.2 Transfer Of Data</h3>
        <p className="text-sm">Your information, including Personal Data, may be transferred to—and maintained on—computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.</p>
        <p className="text-sm">If you are located outside of the United States and choose to provide information to us, please note that we transfer the data, including Personal Data, to the United States and process it there.</p>
        <p className="text-sm">Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
        <p className="text-sm">Zap Reports will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.</p>
        <h3>2.3 Disclosure Of Data</h3>
        <h4>2.4a Change in Control or Sale</h4>
        <p className="text-sm">We may share your personal data as part of a sale, merger or change in control, or in preparation for any of these events. Any other entity which buys us or part of our business will have the right to continue to use your data, but only in the manner set out in this Privacy Policy unless you agree otherwise.</p>
        <h4>2.4b Legal Requirements</h4>
        <p className="text-sm">Zap Reports may disclose your Personal Data in the good faith belief that such action is necessary to:</p>
        <ul>
            <li>Comply with a legal obligation</li>
            <li>Protect and defend the rights or property of Zap Reports</li>
            <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
            <li>Protect the personal safety of users of the Service or the public</li>
            <li>Protect against legal liability</li>
        </ul>
        <h4>2.4c Related Services and Service Providers</h4>
        <p className="text-sm">We may share your personal data with our affiliates to provide and develop our Services.</p>
        <h4>2.4d Service Providers</h4>
        <p className="text-sm">We may employ third party companies and individuals to facilitate our Service (“Service Providers”), to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used.</p>
        <p className="text-sm">These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
        <p className="text-sm">For example, we may use third-party Service Providers (such as Google Analytics) to monitor and analyze the use of our Service.</p>Google Analytics Google Analytics is a web analytics service offered byGoogle that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to personalize ads on its own advertising network. For more information on Google’s privacy practices, please visit the Google Privacy &amp; Terms web page: <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a> ‍
        <h3>2.5 Security of Data</h3>
        <p className="text-sm">The security of your data is important to us, but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>
        
        
        <h2 className="mt-6">3. Information Collection and Use</h2>
        <p className="text-sm">We collect several different types of information for various purposes to provide and improve our Service to you.</p>
        <h3>3.1 Types of Data Collected</h3>
        <h4>3.1a Personal Data</h4>
        <p className="text-sm">While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally identifiable information may include, but is not limited to:</p>
        <ul>
            <li>Email address </li>
            <li>First name and last name</li>
            <li>Phone number</li>
            <li>Address, State, Province, ZIP/Postal code, City</li>
            <li>Demographic information</li>
            <li>Social security number</li>
            <li>Financial information, such as income, assets, and debts</li>
            <li>Name of your employer</li>
            <li>Employment status</li>
            <li>Bankruptcy history</li>
            <li>Cookies and Usage Data</li>
        </ul>
        <h4>3.1b Usage Data</h4>
        <p className="text-sm">We may also collect information that your browser sends whenever you visit our Service or when you access the Service by or through a mobile device (“Usage Data”). This Usage Data may include information such as your computer’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
        <p className="text-sm">When you access the Service by or through a mobile device, this Usage Data may include information such as the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.</p>
        <h4>3.1c Tracking &amp; Cookies Data</h4>
        <p className="text-sm">We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.</p>
        <p className="text-sm">Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyze our Service.</p>
        <p className="text-sm">You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>
        <p className="text-sm">Examples of Cookies we use:</p>
        <ul>
            <li><em>Session Cookies.</em> We use Session Cookies to operate our Service</li>
            <li><em>Preference Cookies.</em> We use Preference Cookies to remember your preferences and various settings.</li>
            <li><em>Security Cookies.</em> We use Security Cookies for security purposes.</li>
        </ul>
        
        
      
        
        <h2 className="mt-6">4. Our Policy on “Do Not Track” Signals under the California Online Protection Act (CalOPPA)</h2>
        <p className="text-sm">Zap Reports takes privacy and security very seriously and strives to put our members first in all aspects of our business. With regard to Do Not Track (DNT), Zap Reports currently does not respond to DNT signals in browsers. We decline to support DNT requests because no DNT standard has been adopted.</p>
        <p className="text-sm">Do Not Track is a preference you can set in your web browser to inform websites that you do not want to be tracked. Usually this option maybe enabled or disabled by visiting the Preferences or Settings page of your web browser.</p>
        
        
        <h2 className="mt-6">5. Legal Basis for Processing Personal Data under the General Data Protection Regulation (GDPR)</h2>
        <p className="text-sm">If you are from the European Economic Area (EEA), Zap Reports Inc.’s legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Data we collect and the specific context in which we collect it.</p>
        <p className="text-sm">Zapp Reports LLC. may process your Personal Data because:</p>
        <ul>
            <li>We need to perform a contract with you</li>
            <li>You have given us permission to do so</li>
            <li>The processing is in our legitimate interests and it is not overridden by your rights</li>
            <li>For payment processing purposes</li>
            <li>To comply with the law</li>
        </ul>
        
    
        <h2 className="mt-6">6. Children’s Privacy</h2>
        <p className="text-sm">Our Service does not address anyone under the age of 18 (“Children”).</p>
        <p className="text-sm">We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from Children without verification of parental consent, we take steps to remove that information from our servers.</p>
        

        <h2 className="mt-6">7. Links to Other Sites</h2>
        <p className="text-sm">Our Service may contain links to other sites that are not operated by us. If you click a third party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
        <p className="text-sm">We have no control over and assume no responsibility for the content, privacy policies, or practices of any third party sites or services.</p>
        
        
        <h2 className="mt-6">8. Changes to This Privacy Policy</h2>
        <p className="text-sm">We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. When the Privacy Policy is changed, we will update the “effective date” at the top of this Privacy Policy.</p>
        <p className="text-sm">You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
        
        
        <h2 className="mt-6">9. Contact Us</h2>
        <p className="text-sm">If you have any questions about these Terms, please contact us by mail at: 611 South DuPoint Highway, Suite 102 Dover, DE 19901 US</p>
    </Container>
)

export default Privacy