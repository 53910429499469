import { Container } from 'reactstrap';
import { Helmet } from 'react-helmet';

const Disclaimer = () => (
    <Container className="py-6">

        <Helmet>
            <title>{`Disclaimer`}</title>
            <meta name="description" content="Disclaimer" />
        </Helmet>


        <h1>Zap Reports - Disclaimer</h1>
        <p className="text-sm"><strong>Last Updated:</strong> September 08, 2021</p>
        
        <hr />

        <h3>Zap Reports is not a Law Firm</h3>
        
        <p className="text-sm">Zap Reports is not a law firm and does not provide legal advice. Accordingly, communications between you and Zap Reports are neither protected by attorney-client privilege nor as work product.</p>
        
        <h3>This Website is for Informational Purposes</h3>
        <p className="text-sm">This website and the information contained herein are for informational purposes only and are not and should not be viewed as legal advice. The purpose of this web site is to provide general information to members of the public who are seeking a licensed lawyer. We do not recommend or endorse any opinions on this site and cannot provide any kind of advice, opinion, or recommendation about possible legal rights, remedies, defenses, options, or strategies. Your access to the website is subject to our Terms and Conditions.</p>
        
        <p className="text-sm">Zap Reports does not assume any liability for the advice offered by any lawyer, or the advice or contents of any material provided on the site or as a result of a lawyer who you might retain as a result of providing information to Zap Reports.</p>
        
        <h3>No Legal Guarantees</h3>
        <p className="text-sm">No representation is made that the quality of the legal services to be performed by attorneys we connect you with is greater than the quality of legal services performed by other lawyers. Any statement, testimonial, or endorsement contained herein does not constitute a guarantee, warranty, or prediction regarding the ultimate result or outcome of your legal matter. Zap Reports does not guarantee a response to your inquires, although in most cases, you should be contacted within 2-3 business days.</p>

    </Container>
)

export default Disclaimer