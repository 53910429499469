import { Route, Switch } from "react-router-dom";
import routes from "../layout/routes";


const Legal = () => (
    <div className="main-conten" >
        <Switch>
            {routes.map((prop, key) => (
                <Route
                    path={`/l${prop.path}`}
                    component={prop.component}
                    key={key}
                />
            ))}
        </Switch>
    </div>
)

export default Legal