import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';

const ArchkDocsMain = ({tabs, settings}) => {

    return (
        <div className="archk-docs-main">

            <Container className="d-block d-sm-none archk-docs-main-logo mt-4">
                <Link to="/">
                    <img src={settings.logo} alt="logo" className="w-100"></img>
                </Link>
            </Container>

            {tabs.map((t1, i1) => (
                <React.Fragment key={i1}>

                <Container >
                    <div
                        id={`archk-docs-main-content-${i1}`}  
                        className="archk-docs-main-content"
                    >
                        <h2 className="archk-docs-main-content-header">{t1.name}</h2>
                        {t1.content}
                    </div>
                </Container>

                {t1.tabs && t1.tabs.length ? t1.tabs.map((t2, i2) => (
                     <Container key={i2}>
                        <div
                            id={`archk-docs-main-content-${i1}-${i2}`}  
                            className="archk-docs-main-content"
                        >
                            <h3 className="archk-docs-main-content-sub-header">{t2.name}</h3>
                            {t2.content}
                        </div>
                    </Container>
                )) : null}

                {i1 !== tabs.length -1 ? <hr /> : null}

                </React.Fragment>
            ))}

            <hr />
            <p className="pb-4 text-white text-center mb-0">Last Updated: {settings.lastUpdated}</p>

        </div>
    )

}

export default ArchkDocsMain;