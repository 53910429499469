import { useEffect } from 'react';

import Main from 'components/system/Docs/Main';
import Sidebar from 'components/system/Docs/Sidebar';

const Docs = ({tabs, settings}) => {

    useEffect(() => {
        document.body.classList.add('archk-docs-body');
        return () => {
            document.body.classList.remove('archk-docs-body')
        }
    }, [])

    return (
        <div id="archk-docs">

            <Sidebar 
                tabs={tabs}
                settings={settings}
            />

            <Main
                tabs={tabs}
                settings={settings}
            />

        </div>
    )

}

export default Docs