import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import TermsContent from './TermsContent'

const Terms = () => (
    <Container className="py-6">

        <Helmet>
            <title>{`Terms Of Service`}</title>
            <meta name="description" content="Terms Of Service" />
        </Helmet>

        <div>
            <h1 className="display-block">Zap Reports - Terms and Conditions</h1>
            <p className="text-sm"><strong>Last Updated:</strong> June 22nd, 2022</p>

            <hr />
            <h2>Introduction</h2>
            <p className="text-sm">Please read these Terms and Conditions (“Terms”, “Terms and Conditions”) carefully before using the <Link className="font-weight-bold text-underline" to="/">https://zapreports.com</Link> website (the “Service”) operated by Zap Reports LLC. (“us”, “we”, or “our”). Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who access or use the Service.</p>
            <p className="text-sm">By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.</p>
            <p className="text-sm">Our <Link className="font-weight-bold text-underline" to="/legal/privacy-policy">Privacy Policy</Link> is incorporated herein by this reference and made an integral part hereof. You should review our <Link className="font-weight-bold text-underline" to="/legal/privacy-policy">Privacy Policy</Link> carefully before agreeing to use our Service because our <Link className="font-weight-bold text-underline" to="/legal/privacy-policy">Privacy Policy</Link> governs our treatment of information about you, including personally identifiable information that you submit to use when you create a profile.</p>


            <h2>License To Use Service</h2>
            <p className="text-sm">Unless otherwise stated, Zap Reports LLC. and/or its licensors own the intellectual property rights published on this service and materials used on <Link className="font-weight-bold text-underline" to="/">https://zapreports.com</Link> and the mobile application. Subject to the license below, all these intellectual property rights are reserved.</p>
            <p className="text-sm">You must not:</p>
            <ul>
                <li>republish material from this service in either print nor digital media or documents (including republication on another website);</li>
                <li>sell, rent or sub-license material from the service;</li>
                <li>show any material from the service in public;</li>
                <li>reproduce, duplicate, copy or otherwise exploit material on this service for a commercial purpose;</li>
                <li>edit or otherwise modify any material on the service;</li>
                <li>redistribute material from this service - except for content specifically and expressly made available for redistribution; or</li>
                <li>republish or reproduce any part of this service through the use of iframes or screenscrapers.</li>
            </ul>

            <p className="text-sm">By using the Service, you attest that you are <b>not a competitor</b> to Zap Reports or it's services and that you are <b>NOT</b> using the service for competitive reasons.</p>
           
            <h2>Service Eligibility</h2>
            <p className="text-sm">To use the Service, you must be a U.S. citizen or permanent resident and must be over the legal age of 18.</p>
            <p className="text-sm">By using the Service, you attest that you are not a competitor and are not using the service for competitive reasons.</p>
            <p className="text-sm">You attest that you have full power and authority to enter into this agreement with us and to be bound by these Terms and Conditions and <Link className="font-weight-bold text-underline" to="/legal/privacy-policy">Privacy Policy</Link> and that doing so will not violate any other agreement to which you are a party.</p>


            <h2>Acceptable Use</h2>
            <p className="text-sm">You must not use this service in any way that causes, or may cause, damage to the service or impairment of the availability or accessibility <Link className="font-weight-bold text-underline" to="/">https://zapreports.com</Link> or the mobile application or in any way which is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity.</p>
            <p className="text-sm">You must not use this service to copy, store, host, transmit, send, use, publish or distribute any material which consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit or other malicious computer software.</p>
            <p className="text-sm">You must not conduct any systematic or automated data collection activities on or in relation to this service without Zap Reports LLC.’s express written consent. This includes:</p>
            <ul>
                <li>scraping</li>
                <li>data mining</li>
                <li>data extraction</li>
                <li>data harvesting</li>
                <li>“framing” (iframes)</li>
                <li>Article “Spinning”</li>
            </ul>
            <p className="text-sm">You must not use this service or any part of it to transmit or send unsolicited commercial communications.</p>
            <p className="text-sm">You must not use this service for any purposes related to marketing without the express written consent of Zap Reports LLC.</p>
            <h2>No Warranties</h2>
            <p className="text-sm">Zap Reports Inc.’s Services are provided “as is” without any representations or warranties, express or implied. Zap Reports LLC. makes no representations or warranties in relation to this service or the information and materials provided on this service.</p>
            <p className="text-sm">The service is provided “as is” and on an “as available” basis. We give no warranty that the service will be free of defects and/or faults. To the maximum extent permitted by law we provide no warranties (express or implied) of fitness for a particular purpose, accuracy of information, compatibility and satisfactory quality.</p>
            <p className="text-sm">Zap Reports LLC. accepts no liability for any disruption or non-availability of the service resulting from external causes including, but not limited to, ISP equipment failure, host equipment failure, communications network failure, power failure, natural events, acts of war or legal restriction and censorship.</p>
            <p className="text-sm">Nothing on this service constitutes, or is meant to constitute, advice of any kind. If you require advice in relation to any legal, financial or medical matter you should consult an appropriate professional.</p>
           
            
            <h2>Legal Disclaimer</h2>
            <p className="text-sm"><strong>I understand and agree that Zap Reports LLC. is not a law firm or an attorney, may not perform services performed by an attorney, and its forms or templates are not a substitute for the advice or services of an attorney. No attorney-client relationship or privilege is created with Zap Reports LLC.</strong></p>
            <p className="text-sm">If, prior to using Zap Reports LLC. I believe that Zap Reports LLC. gave me any legal advice, opinion or recommendation about my legal rights, remedies, defenses, options, selection of forms or strategies, I will not proceed with this purchase, and any purchase that I do make will be null and void.</p>
            <p className="text-sm">Zap Reports makes no representation, guarantee, or warranty (express or implied) as to the legal ability, competence, or quality of representation which may be provided by any of the attorneys, law firms, or legal service providers which use Zap Reports’s platform to provide services to clients.</p>

            

            <h2>Termination</h2>
            <p className="text-sm">We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</p>
            <p className="text-sm">All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>
            <p className="text-sm">We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</p>
            <p className="text-sm">Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service.</p>
            <p className="text-sm">All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>

            <h2>Limitations of Liability and Indemnification</h2>
            <p className="text-sm">You hereby indemnify Zap Reports LLC. and undertake to keep Zap Reports LLC. indemnified against any losses, damages, costs, liabilities and expenses (including without limitation legal expenses and any amounts paid by Zap Reports LLC. to a third party in settlement of a claim or dispute on the advice of Zap Reports LLC. legal advisers) incurred or suffered by Zap Reports LLC. arising out of any breach by you of any provision of these terms and conditions, or arising out of any claim that you have breached any provision of these terms and conditions.</p>
            <p className="text-sm">EXCEPT AS PROHIBITED BY LAW, I WILL HOLD Zap Reports LLC. AND ITS OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES (INCLUDING ATTORNEYS’ FEES AND ALL RELATED COSTS AND EXPENSES OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY ME OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF Zap Reports LLC. HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE PART OF Zap Reports INC., IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR SERVICES AND UNDER NO CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO ME.</p>
            <p className="text-sm">You accept that, as a limited liability entity, Zap Reports LLC. has an interest in limiting the personal liability of its officers and employees. You agree that you will not bring any claim personally against Zap Reports LLC. officers or employees in respect of any losses you suffer in connection with the service.</p>
            <p className="text-sm">Without prejudice to the foregoing paragraph, you agree that the limitations of warranties and liability set out in this service disclaimer will protect Zap Reports LLC. officers, employees, agents, subsidiaries, successors, assigns and sub-contractors as well as Zap Reports LLC.</p>

            <h2>Reasonableness</h2>
            <p className="text-sm">By using this service, you agree that the exclusions and limitations of liability set out in this service disclaimer are reasonable.</p>
            <p className="text-sm">If you do not think they are reasonable, you must not use this service</p>

            <h2>Unenforceable provisions</h2>
            <p className="text-sm">If any provision of this service disclaimer is, or is found to be, unenforceable under applicable law, that will not affect the enforceability of the other provisions of this service disclaimer.</p>

            <h2>Breaches of these terms and conditions</h2>
            <p className="text-sm">Without prejudice to Zap Reports LLC.’s other rights under these terms and conditions, if you breach these terms and conditions in any way, Zap Reports LLC. may take such action as Zap Reports LLC. deems appropriate to deal with the breach, including suspending your access to the service, prohibiting you from accessing the service, blocking computers using your IP address from accessing the service, contacting your internet service provider to request that they block your access to the service and/or bringing court proceedings against you.</p>

            <h2>Variation</h2>
            <p className="text-sm">Zap Reports LLC. may revise these terms and conditions from time-to-time. Revised terms and conditions will apply to the use of this service from the date of the publication of the revised terms and conditions on this service. Please check this page regularly to ensure you are familiar with the current version.</p>

            <h2>Assignment</h2>
            <p className="text-sm">Zap Reports LLC. may transfer, sub-contract or otherwise deal with Zap Reports LLC.’s rights and/or obligations under these terms and conditions without notifying you or obtaining your consent.</p>
            <p className="text-sm">You may not transfer, sub-contract or otherwise deal with your rights and/or obligations under these terms and conditions.</p>
           
            <h2>Severability</h2>
            <p className="text-sm">If a provision of these terms and conditions is determined by any court or other competent authority to be unlawful and/or unenforceable, the other provisions will continue in effect. If any unlawful and/or unenforceable provision would be lawful or enforceable if part of it were deleted, that part will be deemed to be deleted, and the rest of the provision will continue in effect.</p>
           
            <h2>Entire Agreement</h2>
            <p className="text-sm">These terms and conditions, together with Zap Reports LLC.’s <Link className="font-weight-bold text-underline" to="/legal/privacy-policy">Privacy Policy</Link> constitute the entire agreement between you and Zap Reports LLC. in relation to your use of this service, and supersede all previous agreements in respect of your use of this service.</p>
            
            <h2>Accounts</h2>
            <p className="text-sm">When you create an account with us, you must provide us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service.</p>
            <p className="text-sm">You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>

            <h2>Links To Other Web Sites</h2>
            <p className="text-sm">Our Service may contain links to third-party web sites or services that are not owned or controlled by Zap Reports LLC.</p>
            <p className="text-sm">Zap Reports LLC. has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that Zap Reports LLC. shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>
            <p className="text-sm">We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.</p>


            <h2>Governing Law</h2>
            <p className="text-sm">These Terms shall be governed and construed in accordance with the laws of Michigan, United States, without regard to its conflict of law provisions.</p>
            <p className="text-sm">Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.</p>
          
            <h2>Changes</h2>
            <p className="text-sm">We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 15 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
            <p className="text-sm">By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.</p>
            <h2>Contact Us</h2>
            <p className="text-sm">If you have any questions about these Terms, please contact us by mail at: 611 South DuPoint Highway, Suite 102 Dover, DE 19901 US</p>
        </div>

        <h1 className="display-block mt-6">Zap Reports - Service Agreement</h1>
        <p className="text-sm"><strong>Last Updated:</strong> June 22nd, 2022</p>
        <hr />

        <TermsContent />
    </Container>
)

export default Terms