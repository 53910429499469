import Docs from '../pages/index';

const routes = [

    {
        path: "",
        component: Docs,
    },

]

export default routes;