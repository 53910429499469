import keys from 'keys';
import React from "react";
import { Link } from 'react-router-dom';
import { Col, Container, Nav, NavItem, Row } from "reactstrap";
import moment from 'moment';
import A from 'components/markup/links/A'

import ZapReportsLogo from 'assets/img/brand/ZapReportsLogo.png'

const Footer = () => (
    <div className="bg-white pt-7">

        <Container id="footer">
            <div >


                <Row >
                    <Col md={3} className="text-md-left text-center">
                        <Link to="/">
                            <img className="w-75 mb-5" src={ZapReportsLogo} alt="Zap Reports" />
                        </Link>                        
                        <div className="pr-5">
                            <p className="text-sm">Fast, Effecient and Easy Document collection for Lawyers</p>
                            <a href={keys.CLIENT_URL + '/auth/register'}  className='btn btn-block btn-success mt-0 mb-4'>
                                <i className="fas fa-user-plus mr-3" />
                                Sign Up
                            </a>
                        </div>
                    </Col>

                    <Col md={5} >
                        <Row>
                            <Col md={4} xs={4}>
                                <h4 className="lead ">Legal</h4>

                                <Nav className="align-items-center ml-auto ml-md-0"  navbar>
                                    <NavItem  className="d-block">
                                        <Link to="/legal/terms" className="text-sm ">Terms</Link>
                                    </NavItem>
                                    <NavItem  className="d-block">
                                        <Link to="/legal/privacy-policy" className="text-sm ">Privacy</Link>
                                    </NavItem>
                                    <NavItem  className="d-block">
                                        <Link to="/legal/disclaimer" className="text-sm ">Disclaimer</Link>
                                    </NavItem>
                                </Nav>
                            </Col>

                            <Col md={4} xs={4}>
                                <h4 className="lead ">Learn</h4>
                                <Nav className="align-items-center ml-auto ml-md-0"  navbar>
                                    <NavItem  className="d-block">
                                        <Link to="/l/webinar" className="text-sm"><i className="fas fa-play mr-2 text-info " /> Webinar</Link>
                                    </NavItem>
                                    <NavItem  className="d-block">
                                        <Link to="/faq" className="text-sm ">FAQ</Link>
                                    </NavItem>
                                    <NavItem  className="d-block">
                                        <Link to="/docs" className="text-sm ">API Docs</Link>
                                    </NavItem>
                                    
                                </Nav>
                            </Col>

                            <Col md={4} xs={4}>
                                <h4 className="lead ">Access</h4>
                                <Nav className="align-items-center ml-auto ml-md-0"  navbar>
                                    <NavItem  className="d-block">
                                        <A href={`${keys.CLIENT_URL}/auth/login`} className="text-sm  ">Login</A>
                                    </NavItem>
                                    <NavItem  className="d-block">
                                        <A href={`${keys.CLIENT_URL}/auth/register`} className="text-sm  ">Register</A>
                                    </NavItem>
                                    <NavItem  className="d-block">
                                        <A href="/documents/security.pdf" className="text-sm ">Security</A>
                                    </NavItem>
                                </Nav>
                            </Col>
                        </Row>
                    </Col>

                    <Col md={4} >
                        <h4 className="lead ">Company</h4>
                        <p className="text-sm mb-3 font-weight-bold mt-0">Zap Reports LLC</p>
                        <p className="text-sm mb-0">611 South DuPoint Highway Suite 102</p>
                        <p className="text-sm mb-3">Dover, DE 19901 US</p>                       
                    </Col>

                </Row>

            </div>
        </Container>

        <div className="border-top pb-3 pt-3 text-center">
            <Container>
                {/* <Row>
                    <Col xs={6} className="align-self-center"> */}
                        <p className="text-sm mb-0">&copy; {moment().format('YYYY')} Zap Reports LLC</p>
                    {/* </Col>
                    <Col xs={6} className="align-self-center text-right">
                        <button className='btn btn-blok btn-success '>
                            <i className="fas fa-user-plus mr-3" />
                            Sign Up
                        </button>
                    </Col>
                </Row> */}
            </Container>
        </div>

    </div>
)

export default Footer
