import keys from 'keys';
import { Helmet } from 'react-helmet';
import { Container } from 'reactstrap';

const Webinar1 = () => {

    const link = keys.CLIENT_URL + '/auth/register?p=webinar'
 
    return (

        <div id="page-home" style={{overflow: 'hidden'}}>

            <Helmet>
                <title>{`Zap Reports Webinar`}</title>
                <meta name="description" content="Zap Reports Webinar" />
            </Helmet>

            <Container>

                <h2 className="text-center display-3 mt-4 mb-2">Zap Reports Webinar</h2>
                <p className="text-sm text-center">Click The Video Below To Play <i className="fas fa-play text-warning ml-3 " /></p>
                <div className="video-container mt-3" style={{overflow: 'visible'}}>
                    <iframe 
                        style={{zIndex: 2}} 
                        src="https://player.vimeo.com/video/700817917?h=177e0c2d15&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" 
                        frameBorder="0" 
                        allow="autoplay; fullscreen; picture-in-picture" 
                        allowFullScreen 
                        title="Zap Reports Walkthrough.mp4"
                    />
                </div>

                <p className='text-center mb-0 mt-4 text-sm'>Register your account using this link and get $100 of free Zap Reports credits:</p>
                <p className="text-center lead font-weight-bold mt-0"><a className="text-info text-underline" href={link}>{link}</a></p>

            </Container>

        </div>
    )

}

export default Webinar1