import { Helmet } from 'react-helmet';
import { Container } from 'reactstrap';

const Demo = () => {

    return (

        <div id="page-home" style={{overflow: 'hidden'}}>

            <Helmet>
                <title>{`Zap Reports Demo`}</title>
                <meta name="description" content="Zap Reports Demo" />
            </Helmet>

            <Container>
                <div className="video-container mt-3" style={{overflow: 'visible'}}>
                    <iframe 
                        style={{zIndex: 2}} 
                        src="https://player.vimeo.com/video/710377558?h=8d49641bd1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&loop=1" 
                        frameBorder="0" 
                        allow="autoplay; fullscreen; picture-in-picture" 
                        allowFullScreen 
                        title="Zap Reports Walkthrough.mp4"
                    />
                </div>
            </Container>

        </div>
    )

}

export default Demo