import { useState, useCallback, useEffect } from 'react'
import { Helmet } from 'react-helmet';

import { Container, Row, Col, Card, CardHeader, CardTitle, CardBody, CardFooter, Badge } from 'reactstrap';
import A from 'components/markup/links/A'

import Wallet from './images/Wallet.svg'
import CardImg from './images/Card.svg'
import World from './images/World.svg'
import Connect from './images/Connect.svg'
import SearchImg from './images/search.svg'
import Video from './images/video.svg'
import Advantage from './images/Advantage.svg'
import HomePage from './images/HomePage.png'
import IPad from './images/IPad.jpg'
import IPhone from './images/IPhone.jpg'
import Irs from './images/Irs5.svg'
import car from './images/car.svg';
import team from './images/team.svg';
import agreement from './images/agreement.svg';

import microsoft from './images/microsoft.png';
import kashflow from './images/kashflow.png';
import lendingkart from './images/lendingkart.png';
import loanbaba from './images/loanbaba.png';

import clio from './images/clio.jpg';
import zoho from './images/zoho.png';

import allstate from './images/insurance/allstate.png';
import citizens from './images/insurance/citizens.png';

import geico from './images/insurance/geico.png';
import libertymutual from './images/insurance/libertymutual.png';
import mercury from './images/insurance/mercury.png';
import nationwide from './images/insurance/nationwide.png';
import progressive from './images/insurance/progressive.png';
import statefarm from './images/insurance/statefarm.png';
import travelers from './images/insurance/travelers.png';
import usaa from './images/insurance/usaa.png';



import keys from 'keys';
import moment from 'moment';

import Search from './Search';

import Circle from 'components/markup/loading/Circle'

import _total_connections from '_functions/total_connections'
import { formatNumber } from 'utils/currency'

const Type = ({text, children}) => {

    const [index, setIndex] = useState(0)
    const [_text, setText] = useState(text[index].slice(0,1))

    useEffect(() => {

        const timeout = setTimeout(() => {
            const newLength = _text.length + 1;
            if(newLength > text[index].length) {
                setTimeout(() => {
                    if(text[index + 1]) {
                        setText(text[index + 1].slice(0,1))
                        setIndex(index + 1)
                    } else {
                        setTimeout(() => {
                            setText(text[0].slice(0,1))
                            setIndex(0)
                        }, 3000)
                    }
                }, 1000)
                return _text;
            }
            setText(text[index].slice(0, newLength))
        }, 50)

        return () => {
            clearTimeout(timeout)
        }

    }, [text, index, _text, children])

    return _text

}


const Disclaimer = () => {

    const [connections, setConnections] = useState({
        aggregator_1: 0, 
        aggregator_2: 0, 
        aggregator_3: 0,
        yesterday_aggregator_1: 0, 
        yesterday_aggregator_2: 0, 
        yesterday_aggregator_3: 0,
    })

    const fetchConnections = useCallback(async () => {

        const data = await _total_connections.find();
        if(data.data) {
            const today       = data.data[0] ? data.data[0] : {};
            setConnections({
                aggregator_1    : today.aggregator_1 ? today.aggregator_1 : 0,
                aggregator_2    : today.aggregator_2 ? today.aggregator_2 : 0,
                aggregator_3    : today.aggregator_3 ? today.aggregator_3 : 0,
                date            : today.created_at ? today.created_at : 0,
            })
        }

    }, [])

    useEffect(() => {
        fetchConnections();

    }, [fetchConnections])

    return (

        <div id="page-home" style={{overflow: 'hidden'}}>

            <Helmet>
                <title>{`Zap Reports`}</title>
                <meta name="description" content="Zap Reports" />
            </Helmet>

            <div className="header animated-hero py-7">

                <Container className="py-0" >
                    <Row>
                        <Col lg={8} className="align-self-center">
                            <h1 className="display-2 text-uppercase text-white text-italic mb-4" style={{textShadow: '1px 1px 1px #777'}}>
                                {/* <i className="fas fa-check-circle text-white mr-0 " /> */}
                                Pay stubs, bank statements & <b className="text-underline">Tax Transcripts</b> in seconds
                            </h1>
                            <p className="text-sm text-white" ><b className="text-underline">POWER</b> Your Firm and <b className="text-underline">WOW</b> your clients with Zap Reports! <br />White Labeled Entirely To You</p>
                            <p className="text-white lead" style={{fontFamily: 'monospace', minHeight: 80}}>
                            <Type 
                                text={[
                                    "Zap Reports Gets You Pay Stubs,",
                                    "Bank Statements,",
                                    "Auto Insurance Policies,",
                                    "And Tax Transcripts In Seconds,",
                                    "As Well As Uber, Lyft And Doordash",
                                    "And PayPal, CashApp, Venmo, etc!",
                                    "Done Instantly Which Means..",
                                    "Your Clients Are Happy..",
                                    "Your Company Saves Money..",
                                    "And Everyone Enjoys The Process!!!",
                                ]}
                            />
                            </p>
                            
                        </Col>
                        <Col lg={4} className="align-self-center">
                            <img src={HomePage} alt="..." className="w-100 pl-4" />
                        </Col>
                    </Row>
                </Container>

                <div className="separator separator-bottom separator-skew zindex-100" >
                    <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0"><polygon className="fill-white" points="1285 0 2560 100 0 100"></polygon></svg>
                </div>
            </div>

            <div className="bg-white pb-4 pt-5 text-center">
                <Container>

                    <Badge color="success" className="mb-5"><i className="fas fa-check mr-2 " /> A Seamless Experience</Badge>
                    <h2 className="display-2 text-uppercase  text-italic mb-4">Document collection <br />simplified </h2>
                    <p className="text-sm ">Raise the bar for your firm by <b className="text-warning">doing what your competitors won't,</b> getting documents for your clients.</p>

                </Container>
            </div>

            <div className="bg-white pb-9 pt-1 header" >
                <Container className="position-relative pb-4">

                    <img src={Advantage} alt="..." className="d-none d-lg-block" style={{position: 'absolute', left: 20, top: -180, width: 200}} />

                    <Row>
                        <Col lg={4}>
                            <Card className="text-cente  z-depth-1 mt-5" style={{borderRadius: 0}}>
                                <CardHeader className="bg-success" style={{borderBottom: 'solid 1px #ddd', borderRadius: 0}}>
                                    <CardTitle className="mb-0 text-white">Step 1</CardTitle>
                                </CardHeader>
                                <CardBody style={{minHeight: 120}}>
                                    <h3 className="display-5  text-capitalize">Request Information From Clients with a Single Click</h3>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={4}>
                            <Card className="text-cente  z-depth-1 mt-5" style={{borderRadius: 0}}>
                                <CardHeader className="bg-success" style={{borderBottom: 'solid 1px #ddd', borderRadius: 0}}>
                                    <CardTitle className="mb-0 text-white">Step 2</CardTitle>
                                </CardHeader>
                                <CardBody style={{minHeight: 120}}>
                                    <h3 className="display-5  text-capitalize">Clients Securely Provide Credentials to Access Their Institutions</h3>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={4}>
                            <Card className="text-cente  z-depth-1 mt-5" style={{borderRadius: 0}}>
                                <CardHeader className="bg-success" style={{borderBottom: 'solid 1px #ddd', borderRadius: 0}}>
                                    <CardTitle className="mb-0 text-white">Step 3</CardTitle>
                                </CardHeader>
                                <CardBody style={{minHeight: 120}}>
                                    <h3 className="display-5  text-capitalize">Documents and statements are Provided Directly to Your Firm</h3>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="pt-5">
                        <div className="col-auto align-self-center d-none d-md-block" style={{width: 130}} >
                            <div className='z-depth-1 bg-secondary text-center' style={{borderRadius: '100%', padding: '11px 0',}}>
                                <i className="fas fa-user-circle icon text-purple bg-white border" style={{fontSize: 90, height: 90, width: 90, borderRadius: '100%'}} />
                            </div>
                        </div>
                        <Col className="align-self-center">
                            <h3 className="display-5 text-capitalize pl-4 font-weight-normal">Getting documents for my practice has always been a struggle. With Zap Reports, I've reduced my time to file to <b className="text-dark text-underline">just 15 days from a verbal commitment</b>, saved on staff time, and I no longer have to harass clients for documents which feels great!</h3>
                          
                        </Col>
                    </Row>

                    <Row>
                        <div className="col-auto align-self-center d-none d-md-block" style={{width: 130}} >
                        </div>
                        <Col className="align-self-center">
                            <p className="text-left pl-4">- David Ienna, President Fairmax Law</p>
                        </Col>
                    </Row>

                </Container>
                {/* <div className="separator separator-bottom separator-skew zindex-100" >
                    <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0"><polygon className="fill-warning" points="2560 0 2560 100 0 100"></polygon></svg>
                </div> */}
            </div>

            <div className="pb-9 pt-7 bg-gradient-warning header">
                <Container>
                    <Row>

                        <Col lg={7} className="align-self-center">
                            <div className="pr-0 pr-lg-4">
                                <Card className="z-depth-3">
                                    <CardHeader>
                                        <h2 className="display-2 text-uppercase  text-italic mb-4">
                                            <b className=" text-italic d-inline text-danger"><i className="fas fa-ban mr-0 " /> STOP</b>{' '}
                                            Having to deal with the IRS
                                        </h2>
                                    </CardHeader>
                                    
                                    <CardBody className="bg-secondary border-top border-bottom">
                                        <Badge color="success" className="mb-3"><i className="fas fa-link mr-2 " /> Direct IRS Integration</Badge><br />
                                        <p className=" text-secondar mt-0">Zap Reports is proud to be the first service available to law firms that can get your client's tax transcripts via the <b className="text-italic text-underline text-uppercase">Zap Transcripts Service</b>.</p>
                                        <p className="">Now you can leave behind long hours on hold with IRS and forget telling your client's that they are on their own to deal with the IRS.</p>
                                        <p className=" mb-0">Working <b className="text-info"><b className="text-underline">without</b> a wet signature,</b> our transcript service is the fastest way to get documents back from the IRS.</p>
                                    </CardBody>

                                    <CardFooter>
                                        <p className=" mt-0 mb-0">  
                                            With  <b className="text-italic text-uppercase">Zap Reports</b> tax transcripts are at your fingertips.
                                        </p>
                                    </CardFooter>
                                </Card>
                           </div>
                        </Col>
                        
                        <Col lg={5} className="align-self-center">
                            <div className="pl-0 pl-lg-6">
                                <img src={Irs} className=" w-100 d-none d-lg-block" alt="IRS"  />
                                <p className="text-sm text-center text-secondary mt-lg-5 mt-0">*Estimated Launch: June 2022</p>
                            </div>
                        </Col>

                    </Row>
                </Container>

                <div className="separator separator-bottom separator-skew zindex-100" >
                    <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0"><polygon className="fill-white" points="2560 0 2560 100 0 100"></polygon></svg>
                </div>
            </div>

            <div className="bg-white pb-6 pb-md-9 pt-9 header">
                <Container className="position-relative">

                    <Badge color="success" className="mb-4"><i className="fas fa-check mr-2 " />Instant Policy Access</Badge>
                    <h2 className="display-2 mb-0 text-uppercase text-italic"><i className="fas fa-car mr-2 text-info " /> Driving Auto Insurance Policies Right Into Your Hands </h2>

                    <Row>
                        <Col lg={6} className="align-self-center">
                            <p className="mb-4 lead mt-0">If your clients drive, their auto insurance policies and ID cards are a few clicks away.</p>
                            <p className="lead">Zap Reports connects directly to major insurance brands to be able to get you policy statements instantly.</p>

                            <Row className="mb-4 mt-5 text-center text-lg-left">
                                <Col md={2} xs={2} className="align-self-center">
                                    <img src={allstate} className="z-depth-1" alt="allstate" style={{width: 60, height: 60, borderRadius: '100%'}} />
                                </Col>
                                <Col md={2} xs={2} className="align-self-center">
                                    <img src={citizens} className="z-depth-1" alt="citizens" style={{width: 60, height: 60, borderRadius: '100%'}} />
                                </Col>

                                <Col md={2} xs={2} className="align-self-center">
                                    <img src={geico} className="z-depth-1" alt="geico" style={{width: 60, height: 60, borderRadius: '100%'}} />
                                </Col>
                                <Col md={2} xs={2} className="align-self-center">
                                    <img src={libertymutual} className="z-depth-1" alt="libertymutual" style={{width: 60, height: 60, borderRadius: '100%'}} />
                                </Col>
                                <Col md={2} xs={2} className="align-self-center">
                                    <img src={mercury} className="z-depth-1" alt="mercury" style={{width: 60, height: 60, borderRadius: '100%'}} />
                                </Col>
                            </Row>
                            <Row className="text-center text-lg-left">
                                <Col md={2} xs={2} className="align-self-center">
                                    <img src={nationwide} className="z-depth-1" alt="nationwide" style={{width: 60, height: 60, borderRadius: '100%'}} />
                                </Col>
                                <Col md={2} xs={2} className="align-self-center">
                                    <img src={progressive} className="z-depth-1" alt="progressive" style={{width: 60, height: 60, borderRadius: '100%'}} />
                                </Col>
                                <Col md={2} xs={2} className="align-self-center">
                                    <img src={statefarm} className="z-depth-1" alt="statefarm" style={{width: 60, height: 60, borderRadius: '100%'}} />
                                </Col>
                                <Col md={2} xs={2} className="align-self-center">
                                    <img src={travelers} className="z-depth-1" alt="travelers" style={{width: 60, height: 60, borderRadius: '100%'}} />
                                </Col>
                                <Col md={2} xs={2} className="align-self-center">
                                    <img src={usaa} className="z-depth-1" alt="usaa" style={{width: 60, height: 60, borderRadius: '100%'}} />
                                </Col>
                            </Row>

                        </Col>
                        
                        <Col lg={6} className="align-self-center">
                            <img src={car} alt="car" className="w-100 p-7 d-none d-md-block " />
                        </Col>

                    </Row>

                </Container>
            </div>

            <div className="bg-white border-top border-bottom pb-6 pb-md-9 pt-6 pt-md-9 header">
                <Container className="position-relative">

                    <div className="mb-6 text-left text-lg-right">
                        <Badge color="warning" className="mb-4"><i className="fas fa-check mr-2 " /> Online Intake Simplified</Badge>
                        <h2 className="display-2 mb-0 text-uppercase text-italic">Get Information From Your <br /> Clients Effortlessly <i className="fas fa-list-alt ml-4 text-purple " /> </h2>
                    </div>

                    <Row>
                         
                        <Col lg={6} className="">
                            <div className="mr-7 mb-4 mb-lg-0">
                                <img src={team} alt="team" className="w-100 border rounded p-4" />
                            </div>
                        </Col>


                        <Col lg={6} className=" text-lg-left">
                            <p className="mb-4 lead mt-0">Zap Reports offers a fully customizable Form Builder to simplify the process of getting information from your clients.</p>
                            <p className="lead">We've spent years of research building an all encompassing bankruptcy intake process that you can use right out of the gate saving you time and money as it's already done for you.</p>
                            {/* <p className="lead">What get's even better is that Zap Forms can be customized at every level to suit you.</p> */}
                            <p className="lead">Your firm is unique, shouldn't your intake process be as well?</p>

                        </Col>

                    </Row>

                </Container>
            </div>
         
            <div className="bg-white pb-6 pt-md-9 pt-6 pt-md-9 header">
                <Container className="position-relative">

                    <div className="mb-6">
                        <Badge color="info" className="mb-4"><i className="fas fa-check mr-2 " /> Agreements Made Easy</Badge>
                        <h2 className="display-2 mb-0 text-uppercase text-italic"><i className="fas fa-file-signature mr-2 text-success " /> Simplify The Process Of Having Your Clients Sign Documents </h2>
                    </div>

                    <Row>
                         
                        <Col lg={6}>
                            <p className="mb-4 lead mt-0">With other document signing platforms, you can only sign by email. With Zap Sign, you can text links directly to your clients as well. </p>
                            <p className="mb-4 lead mt-0">That means you get the agreements you need at the push of a button.</p>
                            <p className="mb-4 lead mt-0">Create templates for the documents you use often and receive final copies back that looks like they were signed in person.</p>


                        </Col>

                        <Col lg={6} className="">
                            <img src={agreement} alt="team" className="w-100 pl-7 " />
                        </Col>


                    </Row>

                </Container>
            </div>

            <div className="py-6 pb-9 header bg-gradient-warning">
                <Container className="text-center py-6">

                    <h4 className="mb-0 display-4 text-uppercase text-italic text-white">Create Your Free Account</h4>
                    <p className="text-secondary">Get $100 of free document pulls by clicking the button below.</p>

                    <a href={keys.CLIENT_URL + '/auth/register'} className="btn btn-neutral btn-lg w-50">SIGN UP NOW</a>
                
                </Container>

                <div className="separator separator-bottom separator-skew zindex-100" >
                    <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0"><polygon className="fill-white" points="2560 0 2560 100 0 100"></polygon></svg>
                </div>
            </div>

            <div className="pt-8 pb-6 pb-md-9 header bg-white">
                <Container className="">
                    <h2 className="display-3 text-uppercase mb-4"><i className="fas fa-lock mr-2 text-warning " /> Let's Talk Security & Reliability</h2>
                    <p className="lead">Zap Reports uses industry standard, tried and true connection methods to access your clients accounts. This very same technology is used by innovative brands around the globe for <b className="text-underline">underwriting purposes, loan applications, and financial wellness analysis.</b></p>
                    <p className="lead">The data gotten by Zap Reports is sourced <b className="text-underline">directly from financial and employment institutions</b> giving clients streamlined access to their information.</p>
                    
                    <p className="lead">This same technology that we use under the hood is also used by major companies across the globe <b className="text-underline">like the companies shown below.</b></p>
                    <p className="lead">For an in depth look at how Zap Reports priorities security and data integrity please refer to our <A className="text-underline text-warning text-info" href="/documents/security.pdf">whitepaper here.</A></p>

                </Container>
                {/* <div className="separator separator-bottom separator-skew zindex-100" >
                    <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0"><polygon className="fill-dark" points="2560 0 2560 100 0 100"></polygon></svg>
                </div> */}
            </div>

            <div className="pt-6 pb-6 header bg-gradient-warning">
                <Container className="text-center p-5">
                    <Row>
                        <Col md={3} xs={6} className="align-self-center mb-5 mb-md-0">
                            <img src={microsoft} alt="microsoft" style={{maxWidth: 300}} />
                        </Col>
                        <Col md={3} xs={6} className="align-self-center mb-5 mb-md-0">
                            <img src={lendingkart} alt="lendingkart" style={{maxWidth: 300}} />
                        </Col>
                        <Col md={3} xs={6} className="align-self-center">
                            <img src={loanbaba} alt="loanbaba" style={{maxWidth: 300}} />
                        </Col>
                        <Col md={3} xs={6} className="align-self-center">
                            <img src={kashflow} alt="kashflow" style={{maxWidth: 300}} />
                        </Col>
                    </Row>
                </Container>
                {/* <div className="separator separator-bottom separator-skew zindex-100" >
                    <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0"><polygon className="fill-white" points="2560 0 2560 100 0 100"></polygon></svg>
                </div> */}
            </div>

         
            <div className="bg-secondary pb-9 pt-9 header">
                <Container className="position-relative">

                    <Row>
                     
                        <Col lg={6} className="align-self-center d-none d-md-block">
                            <div style={{
                                display: 'flex',
                                alignItems: 'flex-end',
                                justifyContent: 'center',
                                overflow: 'hidden',
                            }}> 
                                                {/* <img src={Hologram} style={{position: 'absolute', width: '90%', opacity: .5, right: 0, top: -50, bottom: 0, left: 0}} /> */}

                                <figure className='ipad z-depth-4' style={{backgroundImage: `url(${IPad})`}}></figure>
                                <figure className='iphone z-depth-2' style={{backgroundImage: `url(${IPhone})`}}></figure>
                            </div>
                           
                        </Col>
                        <Col lg={6} className="align-self-center text-lg-left">
                            <h2 className="display-2 text-uppercase  text-italic mb-4"><i className="fas fa-check text-success mr-2 " /> Works Across All Devices</h2>
                            <p className=" my-4 lead">Not all of your clients have access to a computer but in todays world<b className="text-underline text-warning"> everyone has a phone!</b></p>
                            <p className="lead">With Zap Reports your clients just click on a link to start pulling their documents, it's that simple.</p>

                        </Col>
                    </Row>

                </Container>
         
                <div className="separator separator-bottom separator-skew zindex-100" >
                    <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0"><polygon className="fill-white" points="2560 0 2560 100 0 100"></polygon></svg>
                </div>
            </div>

            <div id="archk-home-search" className="bg-white pb-6 pb-md-9 pt-4 header">

                <Container>
                    <Row>
                        <Col lg={6} className="align-self-center">
                            <img src={SearchImg} className="w-75 mb-6" alt="..." />
                            <p className="mb-0">Curious about the institutions we connect to? </p>
                            <p className="pb-4">Try searching our network for some right on this page!</p>
                        
                            <h3 className="display-2 text-capitalize">
                                1,505,000+
                            </h3>
                            <h3 className="display-5 text-capitalize"> Supported Employers</h3>

                            <h3 className="display-2 text-capitalize mt-6">
                                {connections.aggregator_1 + connections.aggregator_2 > 0 ? 
                                    formatNumber(connections.aggregator_1 + connections.aggregator_2) :
                                    <div style={{width: 40}}><Circle /></div>
                                }
                            </h3>
                            <h3 className="display-5 text-capitalize">Connected Financial Institutions</h3>

                            {connections.date ? (
                                <p className="text-sm mt-6 text-muted"><small>**Data shown as of: {moment.unix(connections.date).format('MMM Do, YYYY h:mm A')}</small></p>
                            ) : null}
                        </Col>

                        <Col lg={6} className="align-self-center">
                            <h2 className=" mb-0 text-center display-2 mb-3 mt-5 mt-lg-0" id="archk-try-it-out">
                                Try It Out 
                            </h2>
                            <Search />
                        </Col>
                    </Row>
                </Container>

                         
                <div className="separator separator-bottom separator-skew zindex-100" >
                    <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0"><polygon className="fill-secondary" points="2560 0 2560 100 0 100"></polygon></svg>
                </div>
            </div>

            <div className="pt-6 pb-9 header">
                <Container>
                    <Row>

                        <Col md={5} className="align-self-center">
                        
                            <Badge color="info" className="mb-4"><i className="fas fa-dollar-sign mr-2 " /> Straight forward billing</Badge>
                            <h2 className="display-2 text-uppercase  text-italic  mb-4">No Monthly Fees - Ever</h2>
                            <p className="text-sm ">We're here to make the document collection world better, not to charge you an arm and a leg. With us, you will now exactly what you'll pay every month.</p>
                            <img src={Wallet} alt="..." className="w-100"></img>

                        </Col>
                        
                        <Col md={2} />

                        <Col md={5} className="align-self-center">
                            <div className="text-center d-none d-md-block">
                                <img src={CardImg} alt="..." className="w-75"></img>
                            </div>
                            <Badge color="warning" className="mb-0 mb-4 mt-6 "><i className="fas fa-chart-line mr-2 " />Flexible Pricing</Badge>

                            <h2 className="display-2 text-uppercase  text-italic mb-4">Pay As You Go</h2>
                            <p className="text-sm ">Do not worry if you have a slow month. It happens to every firm. Zap Reports only charges you for the reports that you pull. No Monthly Minimums and no charges for clients who don't connect their accounts.</p>

                            <button className="btn btn-success"><i className="fas fa-user-plus mr-2 " /> Try Zap Reports For Free</button>

                            {/* <div className="text-center d-block d-md-none">
                                <img src={CardImg} alt="..." className="w-75"></img>
                            </div> */}
                        </Col>

                    </Row>
                </Container>
{/* 
                <div className="separator separator-bottom separator-skew zindex-100" >
                    <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0"><polygon className="fill-warning" points="2560 0 2560 100 0 100"></polygon></svg>
                </div> */}

            </div>


            <div className="pt-6 pb-9 bg-gradient-warning header">
                <Container>

                    <h2 className="display-2 text-uppercase text-center text-italic mb-6 text-white">What Can Zap Reports Access?</h2>

                    <Row>

                        <Col md={4} className="align-self-center">
                            <ul className="ml-0 mb-0 pl-0">
                                <li className="list-style-none text-white"><i className="fas fa-check mr-2 text-yello " /> Financial Institutions</li>
                                <li className="list-style-none text-white"><i className="fas fa-check mr-2 text-yello " /> Cash App</li>
                                <li className="list-style-none text-white"><i className="fas fa-check mr-2 text-yello " /> Venmo</li>
                                <li className="list-style-none text-white"><i className="fas fa-check mr-2 text-yello " /> PayPal</li>
                                <li className="list-style-none text-white"><i className="fas fa-check mr-2 text-yello " /> Zelle</li>
                                <li className="list-style-none text-white"><i className="fas fa-check mr-2 text-yello " /> Mortgages</li>
                            </ul>
                        </Col>

                        <Col md={4} className="align-self-center">
                            <ul className="ml-0 mb-0 pl-0">
                                <li className="list-style-none text-white"><i className="fas fa-check mr-2 text-yello " /> Payroll</li>
                                <li className="list-style-none text-white"><i className="fas fa-check mr-2 text-yello " /> Pay Stubs</li>
                                <li className="list-style-none text-white"><i className="fas fa-check mr-2 text-yello " /> Unemployment</li>
                                <li className="list-style-none text-white"><i className="fas fa-check mr-2 text-yello " /> Uber</li>
                                <li className="list-style-none text-white"><i className="fas fa-check mr-2 text-yello " /> Lyft</li>
                                <li className="list-style-none text-white"><i className="fas fa-check mr-2 text-yello " /> Doordash</li>
                            </ul>
                        </Col>
                        
                        <Col md={4} className="align-self-center">
                            <ul className="ml-0 mb-0 pl-0">
                                <li className="list-style-none text-white"><i className="fas fa-check mr-2 text-yello " /> Auto Policies</li>
                                <li className="list-style-none text-white"><i className="fas fa-check mr-2 text-yello " /> Retirement</li>
                                <li className="list-style-none text-white"><i className="fas fa-check mr-2 text-yello " /> Investments</li>
                                <li className="list-style-none text-white"><i className="fas fa-check mr-2 text-yello " /> Automotive</li>
                                <li className="list-style-none text-white"><i className="fas fa-check mr-2 text-yello " /> Social Security</li>
                                <li className="list-style-none text-white"><i className="fas fa-check mr-2 text-yello " /> Disability</li>
                                <li className="list-style-none text-white"><i className="fas fa-plus mr-2 text-yellow " /> Most Major Institutions</li>
                            </ul>
                        </Col>

                    </Row>

                </Container>

                <div className="separator separator-bottom separator-skew zindex-100" >
                    <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0"><polygon className="fill-white" points="2560 0 2560 100 0 100"></polygon></svg>
                </div>

            </div>
            
            {/* <div className="pt-6 pb-9 bg-secondary header">
                <Container>

                    <Row className="my-6"> 
                        <Col md={6} className="align-self-center">
                            <h2 className="display-3 text-uppercase text-italic">Plug & Play <br /> By Design</h2>
                        </Col>
                        <Col md={6} className="align-self-center">
                            <p className="mb-0">Zap Reports is designed to be able to be used by your company in under 10 minutes. This includes training, onboarding etc</p>
                        </Col>
                    </Row>
                    <Row className="my-6"> 
                        <Col md={6} className="align-self-center">
                            <h2 className="display-3 text-uppercase text-italic">Engineered For  <br />Use At Scale</h2>
                        </Col>
                        <Col md={6} className="align-self-center">
                            <p className="mb-0">Zap Reports is designed to be able to be used by your company in under 10 minutes. This includes training, onboarding etc</p>
                        </Col>
                    </Row>
                    <Row className="my-6"> 
                        <Col md={6} className="align-self-center">
                            <h2 className="display-3 text-uppercase text-italic">Plug & Play <br /> By Design</h2>
                        </Col>
                        <Col md={6} className="align-self-center">
                            <p className="mb-0">Zap Reports is designed to be able to be used by your company in under 10 minutes. This includes training, onboarding etc</p>
                        </Col>
                    </Row>

                </Container>

                <div className="separator separator-bottom separator-skew zindex-100" >
                    <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0"><polygon className="fill-white" points="2560 0 2560 100 0 100"></polygon></svg>
                </div>

            </div> */}

            <div className="pt-6 pb-3 pb-md-9 header bg-white">
                <Container>

                    <Row className="mb-6">
                        <Col md={2}></Col>
                        <Col md={8}>
                            <p className="text-center lead">While Zap Reports works <b className="text-underline text-dark">without needing any installation or custom development,</b> we also offer connections directly to your CMS as an option you can explore.</p>
                        </Col>
                        <Col md={2}></Col>
                    </Row>

                    <Row>

                        <Col lg={5} className="align-self-center">
                        
                            <Badge color="success" className="mb-4"><i className="fas fa-code mr-2 " /> Developer Friendly</Badge>
                            <h2 className="display-2 text-uppercase  text-italic  mb-4">Simple, Easy API's</h2>
                            <p className="text-sm ">We make it as easy as possible for developers to communicate directly to Zap Reports. Connect in hours, not days. </p>
                            <img src={World} alt="..." className="w-100"></img>

                        </Col>
                        
                        <Col lg={2} />

                        <Col lg={5} className="align-self-center">
                            <div className="text-center">
                                <img src={Connect} alt="..." className="w-75"></img>
                            </div>
                            <Badge color="info" className="mb-0 mb-4 mt-8 mt-md-6 "><i className="fas fa-chart-line mr-2 " />Workflow Ready</Badge>

                            <h2 className="display-2 text-uppercase  text-italic mb-4">Open Integration</h2>
                            <p className="text-sm ">Zap Reports has the ability to connect to your existing Case Management Platform so your documents show up instantly.</p>

                            <Row className="border rounded py-3">
                                <Col xs={6}>
                                    <img style={{width: '100%', maxWidth: 125}} alt="clio" src={clio} />
                                </Col>
                                <Col xs={6} className="text-right">
                                    <img style={{width: '100%', maxWidth: 125}} alt="zoho" src={zoho} />
                                </Col>
                            </Row>

                        </Col>

                    </Row>
                </Container>

            </div>

            <div id="archk-home-demo" className="pt-0 pt-md-6 pb-9 header bg-white" >
                <Container>
                <Badge color="warning" className="mb-0 mb-4 mt-8 mt-md-6 "><i className="fas fa-video mr-2 " />Zap Reports In Action</Badge>

                    <h2 className="display-2 text-uppercase text-left  text-italic text-whit mb-2">How it works</h2>
                    <p className="text-sm  mb-4 text-left text-whit">
                        <i className="fas fa-play mr-2 text-info " /> WATCH THE VIDEO
                    </p>

                    <div className="video-container" style={{overflow: 'visible'}}>
                        <iframe 
                            style={{zIndex: 2}} 
                            src="https://player.vimeo.com/video/680982460?h=ecd2ff6c48&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" 
                            frameBorder="0" 
                            allow="autoplay; fullscreen; picture-in-picture" 
                            allowFullScreen 
                            title="Zap Reports Walkthrough.mp4"
                        />
                        <img 
                            className="d-none d-md-block"
                            src={Video} 
                            alt='..'
                            style={{
                                zIndex: 1,
                                width: 500,
                                position: 'absolute',
                                top: -250,
                                right: -200
                            }} 
                        />
                    </div>
                </Container>

                <div className="separator separator-bottom separator-skew zindex-100" >
                    <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0"><polygon className="fill-secondary" points="2560 0 2560 100 0 100"></polygon></svg>
                </div>

            </div>

            <div className="py-6 pb-9 header">
                <Container className="text-center py-6">

                    <h4 className="mb-0 display-4 text-uppercase text-italic">Are you ready to revolutionize your firm?</h4>
                    <p>Get started by clicking the button below.</p>

                    <a href={keys.CLIENT_URL + '/auth/register'} className="btn btn-info btn-lg w-50">SIGN UP NOW</a>
                
                </Container>

                <div className="separator separator-bottom separator-skew zindex-100" >
                    <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0"><polygon className="fill-white" points="2560 0 2560 100 0 100"></polygon></svg>
                </div>
            </div>


        </div>
    )

}

export default Disclaimer