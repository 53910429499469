import Page1 from '../pages/Page1/index';

import Webinar1 from '../pages/Webinar1/index';
import Webinar2 from '../pages/Webinar2/index';

import Demo from '../pages/Demo/index';

const routes = [

    {
        path: "/1",
        component: Page1,
    },

    {
        path: "/demo",
        component: Demo,
    },

    {
        path: "/webinar/access",
        component: Webinar2,
    },
    
    {
        path: "/webinar",
        component: Webinar1,
    },
   

]

export default routes;