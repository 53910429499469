import Home from '../pages/Home';
import Pricing from '../pages/Pricing';

const routes = [

    {
        path: "/pricing",
        component: Pricing,
    },
    
    {
        path: "",
        component: Home,
    },
    

]

export default routes;